import { getDefaultImageLink } from './utils/utils';

export default {
  defaultProductImage: getDefaultImageLink('static/img/default_product.png'),
  stripeApiKey:
      'pk_live_51BpO4cFk6kSQr1yiKxOZfKUZn2eroL7TQUmBRd3A5jgksd8qa55VJAhXTHlddl64a2C5qbvK4vvlDHhohYDqTgke00k4D0zTtg',
  stripeApiKeyTest:
      'pk_test_51BpO4cFk6kSQr1yim874QpwS079d8WpMgvXahaajlcLzB9lLKxy38xSHJQrLHOwR1Z1g1AuGe5NAzIl4h01hxtK000CCWDQL8B',
  iosAppLink: 'https://apps.apple.com/us/app/good-face-app/id1497278219',
  iosAppLink_Footer: 'https://thegoodfaceproject.onelink.me/dp4i/footerdownloadapp',
  iosAppLink_Header: 'https://thegoodfaceproject.onelink.me/dp4i/downloadapp',
  iosAppLink_Home: 'https://thegoodfaceproject.onelink.me/dp4i/hpdownloadapp',
  iosAppLink_Landing: 'https://thegoodfaceproject.onelink.me/dp4i/applpdownloadapp',
  apiBaseUrlDevelopment: 'https://beta.api.thegoodfaceproject.com/api/website',
  apiBaseUrlBeta: 'https://beta.api.thegoodfaceproject.com/api/website',
  apiBaseUrlProduction: 'https://api.thegoodfaceproject.com/api/website',
  apiBaseUrlDev: 'https://api.dev.thegoodfaceproject.com/api/website',
  apiBaseUrlStg: 'https://api.stg.thegoodfaceproject.com/api/website',
  apiBaseUrlPilot: 'https://api.pilot.thegoodfaceproject.com/api/website',
  promoBarLink: 'https://thegoodfaceproject.com/articles/beauty_matter_next_award',

  sentryApiUrl: 'https://e31b9fea10f2470686e26d17d8cbe567@sentry.thegoodfaceproject.com/7',

  apiBaseSocketUrlBeta: 'wss://beta.thegoodfaceproject.com/rest_api/auth/websocket-notifications',
  apiBaseSocketUrlDev: 'wss://api.dev.thegoodfaceproject.com/rest_api/auth/websocket-notifications',
  apiBaseSocketUrlStg: 'wss://api.stg.thegoodfaceproject.com/rest_api/auth/websocket-notifications',
  apiBaseSocketUrlPilot: 'wss://api.pilot.thegoodfaceproject.com/rest_api/auth/websocket-notifications',
  apiBaseSocketUrlProduction: 'wss://api.thegoodfaceproject.com/rest_api/auth/websocket-notifications',
  apiBaseSocketUrlDevelopment: 'wss://beta.thegoodfaceproject.com/rest_api/auth/websocket-notifications',

  analytic: {

    classes: {
      // General partials
      header: 'gfp-header',
      footer: 'gfp-footer',
      // Single product partials
      singleProduct: 'gfp-single-product',
      singleProductMain: 'gfp-single-product-main',
      singleProductSafestMatch: 'gfp-single-product-safest-match',
      singleProductOtherSafestMatches: 'gfp-single-product-other-safest-matches',
      // Single Regular Article
      singleRegularArticle: 'gfp-single-regular-article',
      // Single Review
      singleReviewArticle: 'gfp-single-review-article',
      // General search form
      searchForm: 'gfp-search-form',
      searchFormInput: 'gfp-search-form-input',
      searchFormBtn: 'gfp-search-form-btn',
      // Request review form
      requestReviewForm: 'gfp-request-review-form',
      requestReviewFormBtn: 'gfp-request-review-form-btn',
      // Request review popup
      requestReviewPopupForm: 'gfp-request-review-popup-form',
      requestReviewPopupFormBtn: 'gfp-request-review-popup-form-btn',
      // Sign Up form
      signUpForm: 'gfp-sign-up-form',
      signUpFormBtn: 'gfp-sign-up-form-btn',
      // Subscription form
      subscriptionForm: 'gfp-subscription-form',
      subscriptionFormBth: 'gfp-subscription-form-btn',
      // Subscription popup form
      subscriptionPopupForm: 'gfp-subscription-popup-form',
      subscriptionPopupInput: 'gfp-subscription-popup-input',
      subscriptionPopupBtn: 'gfp-subscription-popup-btn',
      // Subscription page form
      subscriptionPageFormBth: 'gfp-subscription-page-form-btn',
      // Check price
      checkPriceBtn: 'gfp-check-price-btn',
      checkPriceLink: 'gfp-check-price-link',
      // View full formula button
      viewFullFormulaBtn: 'gfp-view-full-formula-btn',
      // Android notify flyout
      androidNotifyPopupForm: 'gfp-android-notify-popup-form',
      androidNotifyPopupFormInput: 'gfp-android-notify-popup-form-input',
      androidNotifyPopupFormBtn: 'gfp-android-notify-popup-form-btn',
      // Promo Bar
      promoBar: 'gfp-promo-bar'
    }

  },
  featureFlagsKeyDev: 'iYtMTrBmKmrqxSxghPSRKR',
  featureFlagsKeyProd: '49KziAHXN9K7vZUqxoaS3E',
  featureFlagsKeyStg: 'hz3KB7Wog4Kbdzzg5NYxvq',
  featureFlagsKeyPilot: 'goPjKQHsDLjEFhBxdBUyjK',
  featureFlagsKeyLocalhost: 'JtuXSqda4HhND824G7zoYw',

}
