import { ACCOUNT_LIMITED_POLICY_MESSAGE, FORMULA_COPIED_TO_IN_MARKET } from '../../../data/messages';

export const MOVE_TO_GROUP = 'MoveToGroup';
export const MOVE_TO_FOLDER = 'MoveToFolder';
export const MORE = 'More';
export const GROUP = 'group';
export const GROUPS = 'Groups';
export const FORMULAS = 'Formulas';
export const FOLDER = 'folder';
export const FOLDERS = 'Folders';
export const TYPES = 'Types';
export const CATEGORIES = 'categories'
export const SUBCATEGORIES = 'subcategories'
export const LARGE = 'large';
export const GENERATING_REPORTS = 'Generating Reports';
export const GENERATING_REPORT = 'Generating Report';
export const COPY_TO_IN_MARKET_SINGLE = 'copy_to_in_market_single';
export const COPY_TO_IN_MARKET_BULK = 'copy_to_in_market_bulk';
export const COPY_TO_IN_MARKET_PROCESSED = 'copy_to_in_market_processed';
export const MOVE = 'Move';
export const CREATE = 'Create';
export const RENAME = 'Rename';
export const DELETE_FOLDER = 'DeleteFolder';
export const DELETE_FOLDERS = 'DeleteFolders';
export const DELETE_FORMULAS = 'DeleteFormulas';
export const DELETE_GROUPS = 'DeleteGroups';
export const INITIAL = 'Initial';
export const DECREASE = 'decrease';
export const INCREASE = 'increase';
export const TRUE = 'true';
export const ENTER = 'Enter';

export const MAX_TOOLTIP_POSITION = 450;
export const MAX_TITLE_LENGTH = 100;

export const REPORTS = {
  'free_of': 'Formulated Without',
  'compliance': 'Regulatory Compliance',
  'benefits': 'Benefits',
  'qq_complete_formula': 'QQ',
  'formula_card': 'ELF Formula Card',
}
export const FILTERS_PARAMS = {
  FOLDER_ID: "folder_id",
  SEARCH: "search",
  CATEGORIES: "category_id",
  SUBCATEGORIES: "subcategory_id",
  TYPES: "product_type_id",
  COMPLIANCES: "toxin_group_ids",
  BLACKLIST: "black_list_ids",
  PRODUCT_SEARCH: "product_search",
  OWNERS: "user_ids",
  BRANDS: "brands",
  FORMATS: "formats",
  STATUS: "status_ids",
  LAST_UPDATED_FROM: 'modified_from',
  LAST_UPDATED_TO: 'modified_to',
  CREATED_FROM: 'created_from',
  CREATED_TO: 'created_to',
  IN_MARKETS: 'in_market',
  SHARED: 'is_shared',
  SMART_FORMULAS: 'smart_ids',
}

export const FILTERS_PARAMS_FOR_FOLDERS = {
  FOLDER_ID: "folder_ids",
  SEARCH: "product_search",
  CATEGORIES: "categories",
  SUBCATEGORIES: "subcategories",
  TYPES: "product_types",
  COMPLIANCES: "toxin_group_ids",
  BLACKLIST: "black_list_ids",
  PRODUCT_SEARCH: "product_search",
  OWNERS: "user_ids",
  BRANDS: "brands",
  FORMATS: "formats",
  STATUS: "status_ids",
  LAST_UPDATED_FROM: 'modified_from',
  LAST_UPDATED_TO: 'modified_to',
  CREATED_FROM: 'created_from',
  CREATED_TO: 'created_to',
  IN_MARKETS: 'in_market',
  SHARED: 'is_shared',
  SMART_FORMULAS: 'smart_ids',
}

export const LIMITED_ACC_MESSAGES = {
  TOXIN_GROUPS:
    ACCOUNT_LIMITED_POLICY_MESSAGE,
  REPORTS:
      'Your account is limited to certain policies. This type of report is not available for basic accounts. ' +
      'If you would like to be able to create these reports, please upgrade your access.',
}

export const NO_PRODUCT_TYPE_MESSAGE = 'Please add the product type in order to generate reports.'

export const FORMULA_COLUMN_SWITCHER_NAMES = {
  product_name: 'Product Name',
  brand_name: "Brand Name",
  formula: 'Formula #',
  status: 'Status',
  product_type: 'Product Type',
  shared: 'Shared Status',
  owner: 'Owner',
  last_update: 'Last Updated',
  created_at: 'Date Created',
}

export const DEFAULT_TABLE_LAYOUT = {
  product_name: true,
  formula: true,
  status: false,
  brand_name: false,
  product_type: true,
  shared: true,
  owner: false,
  last_update: false,
}

export const FORMULAS_TITLES = {
  buttonAdd: {
    [GROUPS]: 'Create new group',
    [FOLDERS]: 'Create new folder',
  }
}

export const USERS_WARNING = {
  [MOVE_TO_GROUP]: 'You must select a folder and all its contents in order to move a folder from one group to another',
  [MOVE_TO_FOLDER]: 'You must select a formula in order to move a formula from folder to another folder',
  [TYPES]: 'Reports are not yet available for household product types',
  [LARGE]: 'You\'re generating a very large report which may take some time. Please check "my reports" later to see progress and download the completed report.',
  [GENERATING_REPORTS]: 'Reports are generating. They will be available in My Reports shortly.',
  [GENERATING_REPORT]: 'Report is generating. It will be available in My Reports shortly.',
  [COPY_TO_IN_MARKET_SINGLE]: FORMULA_COPIED_TO_IN_MARKET,
  [COPY_TO_IN_MARKET_BULK]: 'The copying process is complete. Please check "In-Market Products".',
  [COPY_TO_IN_MARKET_PROCESSED]: 'We are processing your request.',
}

export const ACCOUNT_HAVE_NO_PERMISSION = 'Your account doesn\'t have permission to perform this action. Contact your account admin or customer success manager to update your permissions.'

export const TYPES_PARSING = {
  MOVE, CREATE, RENAME, DELETE_FOLDERS, DELETE_FORMULAS, INITIAL,
}

export const OPENED_MODALS = {
  DELETE_FORMULAS, DELETE_FOLDER, DELETE_FOLDERS, DELETE_GROUPS,
}

export const FILTERS_TYPES = {
  FOLDER: 'folders',
  SMART_FORMULAS: 'smartFormulas',
  PRODUCT_TYPE: 'types',
  COMPLIANCE: 'compliances',
  BRAND: 'brand',
  CATEGORY: 'categories',
  SUBCATEGORY: 'subcategories',
  LAST_UPDATED: 'lastUpdated',
  OWNER: 'owners',
  FORMAT: 'format',
  DATE_CREATED: 'created',
  STATUS: 'status',
  SHARED: 'shared',
  IN_MARKET: 'inMarkets',
}
export const FILTERS_MAIN_TYPES = {
  FOLDER: FILTERS_TYPES.FOLDER,
  PRODUCT_TYPE: FILTERS_TYPES.PRODUCT_TYPE,
  COMPLIANCE: FILTERS_TYPES.COMPLIANCE,
}

export const FILTERS_MAIN_TYPES_OLD = {
  FOLDER: FILTERS_TYPES.FOLDER,
  CATEGORY: FILTERS_TYPES.CATEGORY,
  SUBCATEGORY: FILTERS_TYPES.SUBCATEGORY,
  PRODUCT_TYPE: FILTERS_TYPES.PRODUCT_TYPE,
  COMPLIANCE: FILTERS_TYPES.COMPLIANCE,
}

export const FILTERS_GRADATION = {
  WEEK: 'week',
  MONTH: 'month',
  HALF_YEAR: 'half_year',
  RANGE: 'range',
  TRUE: true,
  FALSE: false,
}

export const FILTERS_GRADATION_VALUES = {
  [FILTERS_GRADATION.WEEK]: new Date().setDate(new Date().getDate() - 7),
  [FILTERS_GRADATION.MONTH]: new Date().setMonth(new Date().getMonth() - 1),
  [FILTERS_GRADATION.HALF_YEAR]: new Date().setMonth(new Date().getMonth() - 6),
}

export const FILTERS_TITLES = {
  TRUE: 'Yes',
  FALSE: 'No',
  SEE_MORE: 'See More',
  SEE_LESS: 'See Less',
  [FILTERS_TYPES.FOLDER]: 'Folder',
  [FILTERS_TYPES.SMART_FORMULAS]: 'SmartFormulas',
  [FILTERS_TYPES.PRODUCT_TYPE]: 'Product Type',
  [FILTERS_TYPES.COMPLIANCE]: 'Compliance',
  [FILTERS_TYPES.BRAND]: 'Brand',
  [FILTERS_TYPES.CATEGORY]: 'Category',
  [FILTERS_TYPES.SUBCATEGORY]: 'Subcategory',
  [FILTERS_TYPES.LAST_UPDATED]: 'Last Updated',
  [FILTERS_TYPES.OWNER]: 'Owner',
  [FILTERS_TYPES.FORMAT]: 'Format',
  [FILTERS_TYPES.DATE_CREATED]: 'Date Created',
  [FILTERS_TYPES.STATUS]: 'Status',
  [FILTERS_TYPES.SHARED]: 'Shared',
  [FILTERS_TYPES.IN_MARKET]: 'In-Market',
  MAIN: {
    ALL_FILTERS: 'All Filters',
    SELECTED_FILTERS: 'Selected Filters',
    CREATE_NEW_FOLDER: 'Create New Folder',
  },
  BUTTONS: {
    SCREEN: 'Screen',
    APPLY: 'Apply',
    APPLY_SELECTED: 'Apply Selected',
    CLEAR: 'Clear',
    CLEAR_ALL: 'Clear All',
    MORE_FILTERS: 'More Filters',
    [FILTERS_TYPES.LAST_UPDATED]: {
      [FILTERS_GRADATION.WEEK]: 'Last week',
      [FILTERS_GRADATION.MONTH]: 'Last month',
      [FILTERS_GRADATION.HALF_YEAR]: 'Last 6 months',
      [FILTERS_GRADATION.RANGE]: 'Range',
    },
    [FILTERS_TYPES.DATE_CREATED]: {
      [FILTERS_GRADATION.WEEK]: 'Last week',
      [FILTERS_GRADATION.MONTH]: 'Last month',
      [FILTERS_GRADATION.HALF_YEAR]: 'Last 6 months',
      [FILTERS_GRADATION.RANGE]: 'Range',
    },
    [FILTERS_TYPES.SHARED]: {
      [FILTERS_GRADATION.TRUE]: 'Shared',
      [FILTERS_GRADATION.FALSE]: 'Not Shared',
    },
    [FILTERS_TYPES.IN_MARKET]: {
      [FILTERS_GRADATION.TRUE]: 'In-Market',
      [FILTERS_GRADATION.FALSE]: 'Not In-Market',
    },
  }
}

export const TOOLTIPS_TITLES = {
  productNameIsSync: 'This Product is in In-Market Products',
  productNameIsSmart: 'This is a Smart Formula',
  exportSelected: 'Export Selected to XLS',
  exportAll: 'Export All to XLS',
};

export const MODALS_TITLES = {
  [OPENED_MODALS.DELETE_GROUPS]: 'Delete Selected Group',
  [OPENED_MODALS.DELETE_FOLDER]: 'Delete Selected Formulas and Folder',
  [OPENED_MODALS.DELETE_FOLDERS]: 'Delete Selected Formulas and Folders',
  [OPENED_MODALS.DELETE_FORMULAS]: 'Delete Selected Formulas',
};

export const TREE_DOTS_MENU = {
  GROUPS, FOLDERS, FORMULAS,
};

export const TITLES = {
  GROUP, FOLDER
};
