import React, { useEffect, useState, lazy, Suspense } from 'react';
import { connect, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
  Switch,
} from 'react-router-dom';
import Modal from "react-modal";
import Cookies from "universal-cookie";
import { isAndroid } from "utils/utils";
import UpdateBrowserPopup from "components/Popups/UpdateBrowserPopup";
import { useFlags } from "flagsmith/react";
import { parse } from "query-string";
import dayjs from "dayjs";
import {
  closeSnackbar,
  toggleSnackbar as toggleSnackBar
} from 'actions/snackbar';
import { Helmet } from 'react-helmet';

import store from "./store";
import {
  getAllPermissions,
  getCompanyProfile,
  getUserProfile,
  getUserProfileByToken,
  logOut,
  refreshToken,
  setFeatureFlags,
  getAuthProfileUrl,
} from './actions';
import Loading from "./components/Loading";
import Header from "./portals/Header";
import Footer from "./portals/Footer";
import CookiesAccept from "./components/CookiesAccept";
import ScrollToTop from "./utils/ScrollToTop";
import PressPopup from "./components/Popups/PressPopup";
import ShowStandardMessage from "./components/ShowStandardMessage";
// eslint-disable-next-line import/order
import RequestReviewPopup from "./components/Popups/RequestReview";

import "./scss/theme_v1/fonts.scss";
import "./scss/theme_v2/fonts.scss";

import "./scss/theme_v2/general.scss";
import "./scss/theme_v1/general.scss";
import Page from "./components/Page/Page";
import {FLAGS_LIST} from "./data/featureFlags";
import {useWindowSize} from "./utils/hooks";
import { LogOutAction } from "./utils/logout";
import { isFormulasPage } from "./utils/isFormulasPage";
import SnackBar from "./components/SnackBar";
import { getMaintenanceMessages } from './actions/maintenanceMessages';
import OverdueInvoicePopup from './components/Popups/OverdueInvoicePopup';

const PrivateRoute = ({ children, redirectTo }) => {
  const userVerified = JSON.parse(sessionStorage.getItem("user-info-for-verified"));
  let isAuthenticated = userVerified?.access;

  const path = window.location.pathname;
  const search = window.location.search;
  const query = parse(search);

  if (query.from_superadmin && query.refresh_token) {
    if (query.show_overdue_invoice_modal) {
      const overdueData = {
        overdue: true,
        endDate: query.overdue_invoice_end_date
      };

      window.sessionStorage.setItem('Overdue', JSON.stringify(overdueData));
    }

    sessionStorage.removeItem('user-info-for-verified');
    localStorage.removeItem('last-seen');
    localStorage.removeItem('sessionTimeStamp');
    window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
    window.localStorage.removeItem('CREDENTIALS_FLUSH');

    store.dispatch(getUserProfileByToken(query.refresh_token)).then((data) => {
      if (data) {
        localStorage.setItem('last-seen', JSON.stringify(Date.now()));
        localStorage.setItem('login-timestamp', dayjs());
        localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));

        window.localStorage.setItem(
          'CREDENTIALS_SHARING',
          JSON.stringify(data),
        );
        window.localStorage.removeItem('CREDENTIALS_SHARING');

        window.location.href = path;
      } else {
        return <Redirect to="/accounts/login?type=sso&next=/sa/products" />
      }
    })
  }

  return isAuthenticated ? children : <Redirect to={redirectTo} />;
};

const SSOLoginRoute = () => {
  const search = window.location.search;
  const query = parse(search);

  if (query.token) {
    store.dispatch(getUserProfileByToken(query.token)).then((data) => {
      if (data) {
        if (process.env.REACT_APP_SEGMENT_KEY?.length && data?.user?.id) {
          window.analytics && window.analytics.identify(data.user.id, {
            $distinct_id: data.user.id,
            user_id: data.user.id,
            $email: data.user.email,
            email: data.user.email,
            name: data.user.name,
            $name: data.user.name,
            company: {
              id: data?.user?.company?.id,
              company_id: data?.user?.company?.id,
              name: data?.user?.company?.name,
              company_name: data?.user?.company?.name,
            },
            environment: 'client-side'
          }, {}, () => {
            // segmentsTrackWithCallback({
            //   user: data.user,
            //   name: 'User Logged In',
            //   description: 'User login',
            //   track_fields: {}
            // })
          })
        }

        localStorage.setItem('last-seen', JSON.stringify(Date.now()));
        localStorage.setItem('login-timestamp', dayjs());
        localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));
        const path = window.sessionStorage.getItem('path')
        const formulas = path !== '/' && path?.length ? path : '/fa/formulas'
        const rawMaterial = path !== '/' && path?.length ?  path : '/fa/raw-material'
        window.location.href = !data?.user?.permissions?.find((item) => item.name === 'supplier_basic_account')
          ? formulas
          : rawMaterial
        sessionStorage.removeItem('path')
      } else {
        return <Redirect to="/accounts/login?type=sso&next=/sa/products" />
      }
    })
  } else if (query.message) {
    return <Redirect to={`/accounts/login?type=sso&next=/sa/products&message=${query.message}`} />
  }

  setTimeout(() => {
    if (!query.token && !query.message) {
      window.location.href = 'accounts/login?type=sso&next=/sa/products'
    }
  }, 1000);

  return <Loading isLoading />
}

const GoogleLoginRoute = () => {
  const search = window.location.search;
  const query = parse(search);

  if (query.code) {
    window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
    window.localStorage.removeItem('CREDENTIALS_FLUSH');

    store.dispatch(getAuthProfileUrl({
      code: query.code,
      provider: query.state ? 'google' : 'microsoft',
      ...(query.state && {state: query.state}),
    })).then((data) => {
      if (data) {

        if (data.show_overdue_invoice_modal) {
          const overdueData = {
            overdue: true,
            endDate: data.overdue_invoice_end_date
          };

          window.sessionStorage.setItem('Overdue', JSON.stringify(overdueData));
        }

        localStorage.setItem('last-seen', JSON.stringify(Date.now()));
        localStorage.setItem('login-timestamp', dayjs());
        localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));
        localStorage.setItem('user-info-for-verified', JSON.stringify(data))
        localStorage.setItem('login', 'true')
        localStorage.setItem('LOGIN_EVENT', JSON.stringify({ data: Date.now().toString(), email: data?.user?.email }));

        const path = window.sessionStorage.getItem('path')
        const formulas = path !== '/' && path?.length ? path : '/fa/formulas'
        const rawMaterial = path !== '/' && path?.length ?  path : '/fa/raw-material'

        window.location.href = !data?.user?.permissions?.find((item) => item.name === 'supplier_basic_account')
          ? formulas
          : rawMaterial
        sessionStorage.removeItem('path')
      } else {

        return window.location.href = '/accounts/login?type=google&message=Uh oh! This email address is not associated with a Good Face account. If you believe this is an error, please contact your customer success manager.'
      }
    })
  } else if (query.message) {
    return <Redirect to={`/accounts/login?type=google&next=/sa/products&message=${query.message}`} />
  } else if (query.error) {
    return <Redirect to={`/accounts/login?type=google&next=/sa/products&message=${query.error === 'access_denied' ? 'Access denied' : query.error}`} />
  }

  return <Loading isLoading />
}

// const AsyncSingleProduct = lazy(
//   () => import("./pages/SingleProduct"),
//
// });

// Tmp before waiting design for tablet
// const AsyncSingleProductTablet = lazy(
//   () => import("./pages/SingleProduct/tablet"),
//
// });

const AsyncBrandsLanding = lazy(
  () => import("./pages/BrandsNewLanding"),
);

const AsyncComparingLanding = lazy(
  () => import("./pages/ComparingLanding"),
);

const AsyncCareersLanding = lazy(
  () => import("./pages/CareersLanding"),
);

const AsyncBookDemoLanding = lazy(
  () => import("./pages/BookDemoLanding"),
);

const AsyncRetailersLanding = lazy(
  () => import("./pages/RetailersLanding"),
);

const AsyncRetailLanding = lazy(
  () => import("./pages/RetailLanding"),
);

const AsyncIngredientSuppliersLanding = lazy(
  () => import("./pages/IngredientSuppliersLanding"),
);

const AsyncIngredientContractmanufacturersSuppliersLanding = lazy(
  () => import("./pages/ContractmanufacturersLanding"),
);

const AsyncRegulatoryServicesLanding = lazy(
  () => import("./pages/RegulatoryServicesLanding"),
);

const AsyncBrandsAssignBilling = lazy(
  () => import("./pages/BrandsAssignBilling"),
);

const AsyncTrademark = lazy(
  () => import("./pages/Trademark"),
);

const AsyncAffidavit = lazy(
  () => import("./pages/Affidavit"),
);

const AsyncCreateRetailer = lazy(
  () => import("./pages/CreateRetailer"),
);

const AsyncSingleIngredient = lazy(
  () => import("./pages/SingleIngredient"),
);

// const AsyncArchiveProducts = lazy(
//   () => import("./pages/ArchiveProducts"),
//);

const AsyncSignIn = lazy(
  () => import("./pages/SignIn"),
);

const AsyncVerify = lazy(
  () => import("./pages/Verify"),
);

const AsyncSignUp = lazy(
  () => import("./pages/SignUp"),
);

const AsyncCompletePayment = lazy(
  () => import("./pages/SignUp/components/CompletePayment"),
);

const AsyncPartnerLanding = lazy(
  () => import("./pages/Partner"),
);

const AsyncReview = lazy(
  () => import("./pages/Article/Review"),
);

const AsyncAboutNew = lazy(
  () => import("./pages/AboutNew"),
);

const AsyncGFIGuide = lazy(
  () => import("./pages/GFI-Guide"),
);

const AsyncBlogLanding = lazy(
  () => import("./pages/BlogLanding"),
);

const AsyncSearch = lazy(
  () => import("./pages/Search"),
);

const AsyncArticle = lazy(
  () => import("./pages/Article/Regular"),
);

const AsyncFAQ = lazy(
  () => import("./pages/FAQ"),
);

const AsyncTrust = lazy(
  () => import("./pages/Trust"),
);

const AsyncFAQNew = lazy(
  () => import("./pages/FAQNew"),
);

const AsyncPrivacyPolicy = lazy(
  () => import("./pages/PrivacyPolicy"),
);

const AsyncSaasAgreement = lazy(
  () => import("./pages/SaasAgreement"),
);

const AsyncTermAndConditions = lazy(
  () => import("./pages/TermsAndConditions"),
);

const AsyncEmailSignUp = lazy(
  () => import("./pages/EmailSignUp"),
);

const AsyncSubscriptionPopup = lazy(
  () => import("./components/Popups/SubscriptionPopup"),
  // loading: () => null,
);

const AsyncAndroidNotifyPopup = lazy(
  () => import("./components/Popups/AndroidNotifyPopup"),
  // loading: () => null,
);

const AsyncShopByBrand = lazy(
  () => import("./pages/ShopByBrand"),
  // loading: () => null,
);

const AsyncWebinar = lazy(
  () => import("./pages/Webinar"),
  // loading: () => null,
);

/* B2B Pages */

const B2BHomepage = lazy(
  () => import("./pages-b2b/Homepage"),
);

const B2BFormulator = lazy(
  () => import("./pages-b2b/Formulator"),
);

const B2BAmplify = lazy(
  () => import("./pages-b2b/Amplify"),
);

// const B2BExperts = lazy(
//   () => import("./pages-b2b/Experts"),
//);

const B2BExpertsNew = lazy(
  () => import("./pages-b2b/ExpertsNew"),
);

const AsyncPDFGenerator = lazy(
  () => import("./pages/PDFGenerator"),
);

const AsyncPDFGeneratorFormulator = lazy(
  () => import("./pages/PDFGeneratorFormulator"),
);

const AsyncCertificationGenerator = lazy(
  () => import("./pages/CertificationGenerator"),
);

const AsyncPdfGeneratorIngredient = lazy(
  () => import("./pages/PdfGeneratorIngredient"),
);

const AsyncPdfGeneratorCompliance = lazy(
  () => import("./pages/PdfGeneratorCompliance"),
);

const AsyncPdfMaterialDataSheet = lazy(
  () => import("./pages/PDFMaterialDataSheet"),
);

const AsyncPdfCorrectiveActions = lazy(
  () => import("./pages/PDFCorrectiveActions"),
);

const AsyncFormulatorDocuments = lazy(
  () => import("./pages/FormulatorDocuments"),
);

const AsyncFormulatorRMPublish = lazy(
  () => import("./pages/FormulatorRawMaterialsPublish"),
);

const AsyncFormulatorSPLGenerate = lazy(
  () => import("./pages/FormulatorSPLGenerate"),
);

const AsyncFormulatorAdmin = lazy(
  () => import("./pages/FormulatorAdmin"),
);

const AsyncFormulatorAdminProfile = lazy(
  () => import("./pages/FormulatorAdminProfile"),
);

const AsyncFormulatorFormulasView = lazy(
  () => import("./pages/FormulatorFormulasView"),
);

const AsyncFormulatorAdminViewPartners = lazy(
  () => import("./pages/FormulatorAdminViewPartners"),
);

const AsyncFormulatorPartnershipsFormula = lazy(
  () => import("./pages/FormulatorPartnershipsFormula"),
);

const AsyncFormulatorInsights = lazy(
  () => import("./pages/Cara/FormulatorAdminInsights"),
);

const AsyncCaraDoppelgangers = lazy(
  () => import("./pages/Cara/CaraDoppelgangers"),
);

Modal.setAppElement("body");

const cookies = new Cookies(),
  { sessionStorage } = window;

const userVerified = JSON.parse(sessionStorage.getItem("user-info-for-verified"));

const events = ['click', 'load', 'scroll'];

const App = (props) => {
  const location = useLocation(); // location is passed as a prop using withRouter

  const flags = useFlags(FLAGS_LIST);
  const {
    condition,
    msg,
    showSnackbarError,
    featureFlags,
    allPermissionsLoading,
  } = props;


  const [updateBrowser, setUpdateBrowser] = useState(false);
  const [showOverdueModal, setShowOverdueModal] = useState(false)
  const [showHeader, setShowHeader] = useState(true);

  const footerCondition = location.pathname !== '/search'
    && location.pathname !== '/raw-material-publish'
    && location.pathname !== '/spl-generate'
    && showHeader;

  const isFaPage =
    window.location.pathname.includes('/fa') &&
    !window.location.pathname.includes('faq') &&
    !window.location.pathname.includes('face');
  store.dispatch(setFeatureFlags(flags));
  const overdueData = JSON.parse(window.sessionStorage.getItem('Overdue'))
  const windowSize = useWindowSize();
  const search = window.location.search;
  const query = parse(search);
  const lastSeen = localStorage.getItem('last-seen');
  const currentUser = JSON.parse(sessionStorage.getItem("user-info-for-verified"));
  const currentUserAccess = currentUser?.access;
  const SUPPLIER_BASIC =
    !!props?.companyPermissions?.find((item) => item.name === 'supplier_basic_account');
  const COMPOUND_FORMULATOR =
    !!props?.companyPermissions?.find((item) => item.name === 'compound_formulator');

  const SESSION_TIMEOUT_VALUE = featureFlags?.auto_logout_time?.enabled ? 1800000 : 3600000;

  const handleLogout = async () => {
    const userEmail = window.sessionStorage.getItem('email')

    if (!userEmail){
      window.sessionStorage.setItem('email', props.user?.email);
    }

    await store.dispatch(logOut({ auto_logout: true }));
    LogOutAction();
  };

  if (
    !!userVerified &&
    !query.from_superadmin &&
    (!lastSeen || (Date.now() - lastSeen) > 90000)
  ) {
    handleLogout()
  }

  // else if (query.from_superadmin) {
  //   localStorage.setItem('last-seen', JSON.stringify(Date.now()));
  //   localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));
  //   window.history.pushState({}, '', window.location.pathname);
  // }
  useEffect(() => {
    if (props?.user?.email) {
      window.sessionStorage.setItem('email',  props?.user?.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props?.user?.email])

  useEffect(() => {
    if (overdueData) {
      setTimeout(() => {
        setShowOverdueModal(overdueData.overdue)
      },2500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let refreshTokenInterval = setInterval(() => {
    refreshToken();
    const user = JSON.parse(sessionStorage.getItem("user-info-for-verified"));

    if (user?.refresh) {
      store.dispatch(refreshToken());
    } else {
      clearInterval(refreshTokenInterval);
    }
  }, 600000);


  useEffect(() => {
    const pathsToHideHeader = [
      "/pdf-generator",
      "/certification-generate",
      "/pdf-material-data-sheet",
      "/pdf-corrective-actions",
      "/pdf-compliance-report",
      "/pdf-formulator-generator",
      "/payment-complete",
      "/demo"
    ];

    const shouldShowHeader = !pathsToHideHeader.some(path =>
      location.pathname.includes(path)
    );

    // Defer state updates in useEffect
    setShowHeader(shouldShowHeader);
  }, [location.pathname]);

  useEffect(
    () => {
      events.forEach((event) => {
        window.addEventListener(event, () => {
          const isLoggedIn = !!JSON.parse(sessionStorage.getItem('user-info-for-verified'));
          const currentSessionTimeStamp = JSON.parse(localStorage.getItem('sessionTimeStamp'));
          const diffTime = Date.now() - currentSessionTimeStamp;

          if (isLoggedIn) {
            if (currentSessionTimeStamp && diffTime > 5000) {
              if (diffTime < SESSION_TIMEOUT_VALUE) {
                localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));
              } else {
                handleLogout()
              }
            }
          }
        });
      });

      if (!!userVerified && lastSeen && (Date.now() - lastSeen) < 90000) {
        localStorage.setItem('last-seen', JSON.stringify(Date.now()));
      }

      const updateLastSeen = setInterval(() => {
        const isLoggedIn = !!JSON.parse(sessionStorage.getItem('user-info-for-verified'));
        const sessionTimeStamp = JSON.parse(localStorage.getItem('sessionTimeStamp'));

        if (isLoggedIn) {
          if (!sessionTimeStamp || (Date.now() - sessionTimeStamp) > SESSION_TIMEOUT_VALUE) {
            handleLogout()
          } else {
            localStorage.setItem('last-seen', JSON.stringify(Date.now()));
          }
        }
      }, 60000);

      return () => {
        clearInterval(updateLastSeen);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastSeen]
  );

  useEffect(() => {
    if (currentUser && !props?.companyPermissions && !allPermissionsLoading) {
      store.dispatch(getUserProfile());
      store.dispatch(getAllPermissions(false, isFormulasPage()));
      store.dispatch(getCompanyProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!currentUserAccess) {
      dispatch(getMaintenanceMessages());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserAccess])

  useEffect(
    ()=> {
      const isLoggedIn = !!JSON.parse(sessionStorage.getItem('user-info-for-verified'));
      const { detect } = require('detect-browser');
      const browser = detect();

      if (browser.name === 'firefox') {
        const html = document.querySelector('html');

        html.style.scrollbarColor = '#7F7F7F #F2F2F2'; // Fix Firefox scrollbar color customization.
      }

      if (query?.showSnackBar === 'true') {
        dispatch(toggleSnackBar(
          true,
          'Your account is not active. Please contact your account admin.',
          true,
          true
        ));
        window.history.replaceState({}, '', '/');
      }

      if (isLoggedIn) {
        if (
          (browser.name === 'chrome' && Number(browser.version.split('.')[0]) < 49) ||
          (browser.name === 'firefox' && Number(browser.version.split('.')[0]) < 31 ) ||
          (browser.name === 'Edge' && Number(browser.version.split('.')[0]) < 13) ||
          (browser.name === 'trident' && Number(browser.version.split('.')[0]) < 11) ||
          (browser.name === 'msie' && Number(browser.version.split('.')[0]) < 11) ||
          (browser.name === 'safari' && Number(browser.version.split('.')[0]) < 9)
        )
          {
            setTimeout(() => {
              setUpdateBrowser(true)
              }, 1500);
          }
      }

      //Fix for 3d party cookies
      [...document.querySelectorAll('img')].map((x) => {
        if(x.src?.includes('liadm') || x.src?.includes('idpix')) {
          x.style.display = "none"
        }
      })

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!showHeader) {
    const gfpAppWrap = document.getElementsByClassName('gfp-app-wrap');
    gfpAppWrap[0].classList.remove('-fixed');
  }

  const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation();

    if (location.pathname.match('/.*/$')) {
      return <Redirect replace {...rest} to={{
        pathname: location.pathname.replace(/\/+$/, ""),
        search: location.search
      }}/>
    } else return null
  }

  const getLinkPrefix = () => process.env.REACT_APP_CDN_STATIC_URL ? process.env.REACT_APP_CDN_STATIC_URL : '/static/'

  return (
    <Router>
      <Page>
        <React.Fragment>
          <Suspense fallback={<Loading />}>

            <Helmet>
              <link rel="apple-touch-icon" sizes="180x180" href={`${getLinkPrefix()}img/favicon/apple-touch-icon.png`} />
              <link rel="icon" type="image/png" sizes="32x32" href={`${getLinkPrefix()}img/favicon/favicon-32x32.png`} />
              <link rel="icon" type="image/png" sizes="16x16" href={`${getLinkPrefix()}img/favicon/favicon-16x16.png`} />
              <link rel="manifest" href={`${getLinkPrefix()}img/favicon/site.webmanifest`} />
              <link rel="mask-icon" href={`${getLinkPrefix()}img/favicon/safari-pinned-tab.svg`} color="#5bbad5" />
            </Helmet>
            <RemoveTrailingSlash />
            {showHeader && <Header updateBrowser={updateBrowser} windowSize={windowSize} />}
            {updateBrowser
              ? <div className={'disabled'}></div>
              : ''
            }
             {showHeader && updateBrowser
              ? <UpdateBrowserPopup updateBrowser={updateBrowser} />
              : ''
            }

            <Switch>
              <Route path="/demo" component={AsyncBookDemoLanding} />
              <Route path="/careers" component={AsyncCareersLanding} />
              <Route path="/compare" component={AsyncComparingLanding} />

              <Route path="/brands" component={AsyncBrandsLanding} />
              <Route path="/retail" component={AsyncRetailersLanding} />
              <Route path="/ingredientsuppliers" component={AsyncIngredientSuppliersLanding} />
              <Route path="/regulatoryservices" component={AsyncRegulatoryServicesLanding} />
              <Route path="/contractmanufacturers" component={AsyncIngredientContractmanufacturersSuppliersLanding} />
              <Route path="/retailers" component={AsyncRetailLanding} />
              <Route path="/trademark" component={AsyncTrademark} />
              <Route path="/affidavit" component={AsyncAffidavit} />
              <Route path="/faq" component={AsyncFAQNew} />
              {featureFlags?.trust_page?.enabled && <Route path="/trust" component={AsyncTrust} />}
              <Route path="/consumers/faq" component={AsyncFAQ} />
              <Route path="/f_privacy_policy" component={AsyncPrivacyPolicy} />
              <Route path="/f_saas_agreement" component={AsyncSaasAgreement} />
              <Route
                path="/f_terms_and_conditions"
                component={AsyncTermAndConditions}
              />
              <Route
                path="/ingredients/:ingredientSlug"
                component={AsyncSingleIngredient}
              />
              <Route
                path="/certification-generate-brand/:id"
                component={AsyncCertificationGenerator}
              />
              <Route
                path="/certification-generate-products/:ids"
                component={AsyncCertificationGenerator}
              />
              {/*<Route*/}
              {/*  path="/shop/:category?/"*/}
              {/*  exact*/}
              {/*  component={AsyncArchiveProducts}*/}
              {/*/>*/}
              <Route path="/accounts/login" component={AsyncSignIn} />
              <Route path="/accounts/verify" component={AsyncVerify} />
              <Route path="/accounts/registration" component={AsyncSignUp} />
              <Route path="/payment-complete" component={AsyncCompletePayment} />
              <Route exact path="/brands_register">
                <Redirect to="/" />
              </Route>
              <Route exact path="/good_face_index">
                <Redirect to="/good_face_approved" />
              </Route>
              <Route
                path="/brands_assign_billing"
                component={AsyncBrandsAssignBilling}
              />

              <Route path="/create-retailer" component={AsyncCreateRetailer} />

              <Route path="/search" component={AsyncSearch} />
              <Route path="/shop_by_brand" component={AsyncShopByBrand} />

              <Route path="/" exact component={B2BHomepage} />
              <Route exact path="/consumer">
                <Redirect to="/" />
              </Route>
              <Route exact path="/app">
                <Redirect to="/" />
              </Route>
              {/*<Route path="/consumer" exact component={ConsumerHome}/>*/}
              <Route path="/partner" component={AsyncPartnerLanding} />
              {/*<Route path="/app" component={AsyncAppLanding}/>*/}
              <Route path="/reviews/:slug" component={AsyncReview} />
              <Route path="/articles/:slug" component={AsyncArticle} />
              <Route path="/about" component={AsyncAboutNew} />
              <Route path="/good_face_approved" component={AsyncGFIGuide} />
              <Route path="/blog" component={AsyncBlogLanding} />
              <Route path="/email-signup" component={AsyncEmailSignUp} />
              <Route path="/clean-beauty-retail-webinar" component={AsyncWebinar} />

              <Route path="/pdf-generator/:slug" component={AsyncPDFGenerator} />
              <Route
                path="/pdf-formulator-generator/:slug"
                component={AsyncPDFGeneratorFormulator}
              />
              <Route
                path="/pdf-generator-ingredient/:slug"
                component={AsyncPdfGeneratorIngredient}
              />
              <Route
                path="/pdf-compliance-report/:slug"
                component={AsyncPdfGeneratorCompliance}
              />
              <Route
                path="/pdf-material-data-sheet/:slug"
                component={AsyncPdfMaterialDataSheet}
              />
              <Route
                path="/pdf-corrective-actions/:slug"
                component={AsyncPdfCorrectiveActions}
              />

              <Route
                path="/sso"
                render={(props) => <SSOLoginRoute path="/sso" />}
              />

              <Route
                path="/social_auth/google-callback"
                render={(props) => (
                  <GoogleLoginRoute path="/social_auth/google-callback" />
                )}
              />

              <Route
                path="/rest_api/social_auth/microsoft-callback"
                render={(props) => (
                  <GoogleLoginRoute path="/social_auth/microsoft-callback" />
                )}
              />

              {/* B2B Innovation Platform Pages */}
              <Route path="/formulator" component={B2BFormulator} />
              <Route path="/amplify" component={B2BAmplify} />
              <Route path="/experts">
                <Redirect to="/consumerstudies" />
              </Route>
              <Route path="/consumerstudies" component={B2BExpertsNew} />
              <Route
                path="/fa/:slug/:id?/:name?"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorAdmin {...props} windowSize={windowSize} />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/fa/formulas/:id"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorAdmin {...props} />
                  </PrivateRoute>
                )}
              />

              <Route
                path="/fa-profile/:slug"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorAdminProfile {...props} />
                  </PrivateRoute>
                )}
              />

              <Route
                path="/fa-documents/:id"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorDocuments {...props} />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/raw-material-publish"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorRMPublish {...props} />
                  </PrivateRoute>
                )}
              />

              <Route
                path="/spl-generate"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorSPLGenerate {...props} />
                  </PrivateRoute>
                )}
              />

              <Route
                path="/fa-formulas-edit/:id/:pageType"
                render={(renderProps) =>
                  (props?.user && props?.companyPermissions) && SUPPLIER_BASIC ? (
                    <Redirect
                      to={
                        COMPOUND_FORMULATOR
                          ? "/fa/raw-material"
                          : "/fa/reports"
                      }
                    />
                  ) : (
                    <PrivateRoute path="/fa/:slug">
                      <AsyncFormulatorFormulasView {...renderProps} />
                    </PrivateRoute>
                  )
                }
              />

              <Route
                path="/fa-formulas-insight"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorInsights {...props} />
                  </PrivateRoute>
                )}
              />

              <Route
                path="/fa-reverse-engineering"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorInsights {...props} />
                  </PrivateRoute>
                )}
              />

              <Route
                path="/fa-doppelgangers"
                render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncCaraDoppelgangers {...props} />
                  </PrivateRoute>
                )}
              />

              <Route
                path="/fa-partner-products/:partnerIid/:pageType/:id"
                render={(renderProps) =>
                  (props?.user && props?.companyPermissions) && SUPPLIER_BASIC ? (
                    <Redirect
                      to={
                        COMPOUND_FORMULATOR
                          ? "/fa/raw-material"
                          : "/fa/reports"
                      }
                    />
                  ) : (
                    <PrivateRoute path="/fa/:slug">
                      {featureFlags?.new_partners_api?.enabled
                        ? <AsyncFormulatorPartnershipsFormula {...renderProps} />
                        : <AsyncFormulatorAdminViewPartners {...renderProps} />}
                    </PrivateRoute>
                  )
                }
              />
            </Switch>

            {showOverdueModal &&
              <OverdueInvoicePopup
                isOpen={showOverdueModal}
                endDate={overdueData.endDate}
                closePopup={() => {
                  setShowOverdueModal(false)
                  window.sessionStorage.removeItem('Overdue')
                }}
              />}

            {footerCondition && <Footer />}

            <PressPopup />
            <RequestReviewPopup />
            <ScrollToTop />

            {!cookies.get("gfp-cookies-accepted")
              && showHeader
              && !isFaPage
              && window.location.pathname.indexOf("/raw-material-publish") === -1
              && window.location.pathname.indexOf("/spl-generate") === -1
              && <CookiesAccept />
            }

            <ShowStandardMessage />

            {!cookies.get("gfp-subscribed") &&
              !cookies.get("gfp-subscription-popup") &&
              showHeader && <AsyncSubscriptionPopup />}

            {isAndroid() && <AsyncAndroidNotifyPopup />}

            {condition && (
              <SnackBar
                hideSnackBar={() => store.dispatch(closeSnackbar())}
                showSnackbar={condition}
                message={msg}
                showSnackbarError={showSnackbarError}
              />
            )}
          </Suspense>
        </React.Fragment>
      </Page>
    </Router>
  );
};

export default connect((state) => ({
  user: state.formulator.user,
  companyPermissions: state.formulator.companyPermissions,
  msg: state.snackbar.msg,
  condition: state.snackbar.condition,
  showSnackbarError: state.snackbar.showSnackbarError,
  allPermissionsLoading: state.formulator.allPermissionsLoading,
  featureFlags: state.user.featureFlags
}))(App);
