import React from 'react';
import {
  Section as SectionBulma,
  Container as ContainerBulma,
  Columns as ColumnsBulma,
} from 'react-bulma-components';
import classNames from 'classnames'

import styles from './index.module.scss'

const Section = (props) => {
  const { className = '', ...rest } = props;

  return (
    <SectionBulma className={classNames(styles.section, className)} {...rest} />
  );
};

const Container = (props) => {
  const { className = '', size = '', ...rest } = props;

  return (
    <ContainerBulma
      className={classNames(styles.container, className, {
        [styles['container--sm']]: size === 'sm',
        [styles['container--md']]: size === 'md',
      })}
      {...rest}
    />
  );
};


const Row = ColumnsBulma;
const Column = ColumnsBulma.Column;
const Columns = ColumnsBulma;

export {
  Section,
  Container,
  Columns,
  Row,
  Column
};
