import React, { useCallback, useState, forwardRef } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { useCombobox } from 'downshift';
import { Waypoint } from 'react-waypoint';
import debounce from 'debounce-fn';
import classNames from 'classnames';
import config from 'config';

import axiosInstance from '../../actions/axiosInstance';
import { getApiBase } from '../../utils/utils';
import Icon from '../Icon';
import Input from '../Input_v2';

import styles from './index.module.scss';

const { location } = window;

const SearchBox = forwardRef((props, ref) => {
  const history = useHistory();

  const { q: initialSearchTerm } = qs.parse(location.search);

  const [products, setProducts] = useState([]),
    [page, setPage] = useState(1),
    [isLoadMore, setIsLoadMore] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [isLoaded, setIsLoaded] = useState(false),
    [isError, setIsError] = useState(false);

  let {
    inputValue = '',
    getInputProps,
    getMenuProps,
    getItemProps,
    getComboboxProps,
    isOpen,
    openMenu,
  } = useCombobox({
    items: products,
    itemToString: ({ name: { en: name } }) => name,
    initialInputValue: initialSearchTerm,
    onStateChange: (changes) => {
      if (changes.type === useCombobox.stateChangeTypes.InputChange) {
        onChange(changes.inputValue);
      }
    },
  });

  const onChange = useCallback(
    debounce(
      async (query) => {
        if (query.length < 3) {
          return;
        }

        setProducts([]);
        fetchProducts(query);
      },
      { wait: 500 },
    ),
    [],
  );

  const handleLoadMore = async () => {
    fetchProducts(inputValue, page + 1);
  };

  const onFocusInput = () => {
    const { onFocusInput: onFocus = () => null } = props;
    onFocus();

    if (inputValue.length > 2) {
      openMenu();

      if (!isLoaded) {
        fetchProducts(inputValue);
      }
    }
  };

  const onSubmit = (e) => {
    if (!inputValue.length) {
      e.preventDefault();
    }

    if (location.pathname === '/search') {
      e.preventDefault();
      history.push(location.pathname + '?' + qs.stringify({ q: inputValue }));
    }
  };

  async function fetchProducts(query, page = 1) {
    try {
      setIsLoading(true);
      setIsLoaded(false);
      setIsError(false);

      const result = await axiosInstance
        .get(getApiBase() + '/products', {
          params: {
            page: page,
            q: query,
          },
        })
        .then((resp) => resp.data);

      setPage(page);

      if (page > 1) {
        setProducts([...products, ...result.products]);
      } else {
        setProducts(result.products);
      }

      setIsLoadMore(result['load-more']);
      setIsLoaded(true);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }

  if (!inputValue || inputValue.length < 3) {
    isOpen = false;
  }

  const dropdownStyle = {
    opacity: isOpen ? 1 : 0,
    visibility: isOpen ? 'visible' : 'hidden',
  };

  const {
    placeholder = 'Search Product Database',
    className,
    inputClassName,
    inputWrapClassName,
    btnText,
    btnClassName,
    iconClassName,
    dropdownClassName,
    dropdownItemClassName,
  } = props;

  return (
    <form
      className={classNames(
        styles['search-box'],
        className,
        config.analytic.classes.searchForm,
      )}
      action="/search"
      onSubmit={onSubmit}
      ref={ref}
    >
      <div
        className={classNames(
          styles['search-box__input-wrap'],
          inputWrapClassName,
        )}
        {...getComboboxProps()}
      >
        <button
          className={classNames(
            styles['search-box__btn-search'],
            btnClassName,
            config.analytic.classes.searchFormBtn,
          )}
        >
          <Icon
            className={classNames(
              styles['search-box__icon-search'],
              iconClassName,
            )}
            name="Search"
          />
          {btnText && <span>{btnText}</span>}
        </button>

        <Input
          {...getInputProps({ onFocus: onFocusInput })}
          type="text"
          name="q"
          placeholder={placeholder}
          className={classNames(
            styles['search-box__input'],
            inputClassName,
            config.analytic.classes.searchFormInput,
          )}
        />
      </div>

      <ul
        {...getMenuProps()}
        className={classNames(
          styles['search-box__dropdown'],
          dropdownClassName,
        )}
        style={dropdownStyle}
      >
        {products.map(({ name: { en: name }, url, brand }, index) => (
          <li
            key={index}
            {...getItemProps({
              item: name,
              index,
            })}
            className={classNames(
              styles['search-box__item'],
              dropdownItemClassName,
            )}
          >
            <Link className={styles['search-box__link']} to={url}>
              {name} {brand}
            </Link>
          </li>
        ))}

        {isError && (
          <li className={styles['search-box__msg']}>Loading Error!</li>
        )}

        {isLoading && <li className={styles['search-box__msg']}>Loading...</li>}

        {isLoaded && !products.length && (
          <li className={styles['search-box__msg']}>No results found.</li>
        )}

        {!isLoading && isLoadMore && (
          <li>
            <Waypoint onEnter={handleLoadMore} />
          </li>
        )}
      </ul>
    </form>
  );
});

export default SearchBox;
