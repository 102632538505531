import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import styles from './index.module.scss';

class SnackBar extends Component {
  shouldComponentUpdate(nextProps) {
    const { showSnackbar, message, showSnackbarError, showBtn } = nextProps;

    return (
        // eslint-disable-next-line react/destructuring-assignment
      showSnackbar !== this.props.showSnackbar ||
      // eslint-disable-next-line react/destructuring-assignment
      message !== this.props.message ||
      // eslint-disable-next-line react/destructuring-assignment
      showSnackbarError !== this.props.showSnackbarError ||
      // eslint-disable-next-line react/destructuring-assignment
      showBtn !== this.props.showBtn
    );
  }

  render() {
    const {
      hideSnackBar,
      showSnackbar,
      message,
      showSnackbarError,
      showBtn,
      btnText,
      btnUrl,
      wideSnackbar,
    } = this.props;

    return (
      <OutsideClickHandler onOutsideClick={hideSnackBar}>
        <div
          className={classNames(
            styles['f-admin__snackbar'],
            showSnackbar ? styles['-active'] : '',
            showSnackbarError ? styles['-error'] : '',
              wideSnackbar ? styles['-wide'] : ''
          )}
        >
          <div dangerouslySetInnerHTML={{ __html: message }} />
          {showBtn && !btnUrl.includes('mailto:') && btnUrl.includes('https:') ? <a href={btnUrl} className={styles['f-admin__snackbar-btn']} target="_blank" rel="noopener noreferrer">
            {btnText}
          </a> : showBtn && !btnUrl.includes('mailto:') ? (
            <Link to={btnUrl} className={styles['f-admin__snackbar-btn']} target="_blank">
              {btnText}
            </Link>
          ) : showBtn && btnUrl.includes('mailto:') ? (
            <a href={btnUrl} className={styles['f-admin__snackbar-btn']}>
              {btnText}
            </a>
          ) : null}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default SnackBar;
