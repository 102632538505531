import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

const Heading = (props) => {

  const {size = null, as = 'div', children, className, ...rest} = props;

  const classes = classNames(styles['heading'], className, {
    [styles['heading--1']]: size === 1 || (!size && as === 'h1'),
    [styles['heading--2']]: size === 2 || (!size && as === 'h2'),
    [styles['heading--3']]: size === 3 || (!size && as === 'h3'),
    [styles['heading--4']]: size === 4 || (!size && as === 'h4'),
    [styles['heading--5']]: size === 5 || (!size && as === 'h5'),
    [styles['heading--6']]: size === 6 || (!size && as === 'h6'),
  });

  const opt = {
    ...rest,
    className: classes
  };

  return React.createElement(as, opt, children);

};

export default Heading;
