import React from 'react';
import ReactDOM from "react-dom";

import Header from '../../components/Header';
import useLastLocation from '../../utils/hooks/useLastLocation';

const HeaderWithLastLocation = (props) => {
  const lastLocation = useLastLocation();

  return <Header lastLocation={lastLocation} {...props} />;
};

const HeaderPortal = (props) => ReactDOM.createPortal(
  <HeaderWithLastLocation {...props} />,
  document.getElementById('header'),
);

export default HeaderPortal;
