export const LIMITED_ACC_MESSAGE =
    'You don\'t have permission to perform this action. Contact your account admin or customer success manager to upgrade or update your permissions.';

export const LIMITED_ACC_FEATURE_IS_DISABLED =
  'You don\'t have permission to perform this action. Contact your account admin to update your permissions. ';

export const LIMITED_ACC_CUSTOMER_SUCCESS = 'Contact Customer Success to upgrade your account in order ' +
  'to enable this feature.';

export const LIMITED_ACC_ROLE_USER = 'You must be an account admin in order to configure user permissions.';

export const NO_USERS_PERMISSIONS_PERMISSION = 'Upgrade your account to set up user permissions.';

export const RESET_PASSWORD_SNACKBAR = 'An email was sent to the user with a link to reset their password.';

export const NOT_ADMIN_FOR_SPECIFICATION_PRESETS = 'You must be an account admin to create and edit Specifications Presets.';

export const UPGRADE_ACCESS_MESSAGE = 'You don\'t have permission to perform this action. Contact team@thegoodfaceproject.com to upgrade your account.';

export const TEAM_NAME_MAX_LENGTH = 'Please enter a team name less than 60 characters.';

export const TEAM_NAME_MIN_LENGTH = 'A unique team name must contain at least 3 characters.';

export const RM_LOCKED_MESSAGE = 'You must unlock this Raw Material in order to edit.';

export const RM_PERCENTAGE_PPM_ERROR = 'Concentration accuracy in ppm limited up to 4 decimal places.';

export const SUPPLIER_BASIC_UPGRADE = 'Upgrade your access to Good Face in order to use extra functions';

export const PRODUCT_FORMAT_ALREADY_USED = 'This product format already has an associated preset.';

export const NO_PRODUCT_TYPE_SHARE_MESSAGE =
  'You must add a Product Type in order to share a formula with a partner.';

export const GROUP_NAME_MIN_MAX_LENGTH = 'The Folder Group name must contain between 1 and 100 characters.';

export const SOME_FORMULAS_WHERE_DELETED =
  'Some of the formulas were deleted and can not be copied to the In-Market Products.';

export const INCI_LIMIT_FOR_PRESET = 'You\'ve reached the maximum number of INCIs that can be added to a preset.';

export const PRESET_HAS_NF_INGREDIENTS = 'Please resolve or remove any not found ingredients in order to save the preset.';

export const PRESET_NAME_ERROR = 'Please enter a name for the preset.';

export const PLEASE_SELECT_AT_LEAST_ONE_FORMAT = 'Please select at least one format.';

export const PRESET_NAME_MAX_LENGTH = 'Please enter a preset name less than 100 characters.';

export const YOU_MUST_ADD_LABEL_INGREDIENTS = 'You must add the Label Ingredients to generate the SPL.';

export const INCLUDE_PRODUCT_NAME = 'You must include a Product Name to generate an SPL.';

export const ALL_REQUIRED_FIELDS_FOR_SPL = 'Please complete all required fields in order to generate an SPL.';

export const PRODUCT_NAME_MAX_LENGTH = 'Please enter a product name less than 220 characters.';

export const REPLACEMENT_GROUP_NAME_MAX_LENGTH = 'The Replacement Group name must not exceed 100 characters.';

export const REPLACEMENT_GROUP_NAME_VALIDATION = 'Replacement group names must contain at least 2 characters and up to 100 and be unique'

export const AT_LEAST_ONE_ITEM_SELECTED = 'You must select at least 1 policy or 1 INCI in order to save the preset.';

export const FORMULA_COPIED_TO_IN_MARKET = 'Success! Formula was copied to In-Market Products.'

export const PRODUCT_SUCCESSFULLY_UPDATED = 'Success! Product was successfully updated.'

export const BRAND_NAME_MAX_CHARACTERS = 'Please enter a brand name less than 100 characters.'

export const SHADE_NAME_MAX_CHARACTERS= 'Please enter a product variation name less than 100 characters.'

export const ADD_PARTNER_TO_SHARE_FORMULAS= 'Please add a partner in order to share formulas. '

export const UNEXPECTED_ERROR = 'Oops! We encountered an unexpected error.'

export const UNLOCK_FORMULA_ORDER_MESSAGE = "You must unlock this Formula order to edit or delete.";

export const BULK_ACTION_LIMIT_MESSAGE = "Please select fewer than 100 formulas to perform this bulk action. ";

export const FORMULAS_UNLOCK_REQUIRED_MESSAGE = "You must unlock the selected Formulas order to perform bulk actions.";

export const RAW_MATERIAL_ORDER_LOCKED_WARNING = "You must unlock this Raw Material order to edit or delete.";

export const RAW_MATERIALS_LOCKED_ERROR = "You must unlock the selected Raw Materials order to perform bulk actions.";

export const RAW_MATERIAL_UNPUBLISHED_SUCCESS = "Success! The Raw Material was unpublished from the Trade Name Library.";

export const UNPUBLISH_BEFORE_DELETE = "You must unpublish this material from the Trade Name Library in order to delete it from My Raw Materials.";

export const INGREDIENT_SUBMITTED_FOR_REVIEW = "Success! This ingredient has been pushed to our team for review.";

export const CONCENTRATION_VALUE_RANGE_ERROR = "Please enter a concentration value between 0 and 100%.";

export const FORMULA_NAME_LENGTH_ERROR = "Please enter a formula name less than 220 characters.";

export const SCREENING_SELECTED_POLICIES_MESSAGE = "Hang on! Screening selected policies.";

export const SELECT_PRODUCT_TYPE_MESSAGE = "Please select a product type in order to screen for compliance.";

export const ACCOUNT_LIMITED_POLICY_MESSAGE = "Your account is limited to certain policies. Contact your customer success manager or team@theoogdfaceproject.com to upgrade for access to additional policies.";
export const LIMITED_ACC_LOCK_IS_DISABLED =
  'This formula is locked. Users with permission to lock/unlock may unlock this formula to edit.';

export const FORMULA_UNLOCKED_REQUIRED_FOR_COMPLIANCE_SCREENING = 'The formula must be unlocked in order to screen for compliance'

export const FORMULA_UNLOCKED_REQUIRED_FOR_STATUS_CHANGE = 'The formula must be unlocked in order to change the status'
export const FILES_FOR_ARCHIVE_NOT_FOUND = 'Files for archive not found.';
export const ADD_VARIATION_DISABLED = 'You must first add a SmartFormula to act as the common base before you can create product variations.';
export const ADD_LABEL_ERROR = 'First, add all label ingredients on the product details page in order to designate ingredients by product variation.';

