/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import Sticky from 'react-stickynode';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import config from 'config';
import Cookies from "universal-cookie";

import { Section, Container } from '../Layout';
import Logo from '../Logo';
import SearchBox from '../SearchBox';
import HeaderFA from '../HeaderFa';
import Button from '../Button_v2';
import SlideDown from '../SlideDown';
import Drawer from '../Drawer_v2';
import { ReactComponent as MenuToggle } from '../../assets/img/Icons/Misc/Menu.svg';
import { ReactComponent as LoginIcon } from '../../assets/img/Icons/Misc/Login-2.svg';
import {URLS_DETECT} from "../Footer/consts";
import {setScrolledHeader} from "../../utils/header";
import {segmentsFooterTrack} from "../../utils/trackEvents";

import PromoBar from './PromoBar';
import NoticeLine from './NoticeLine';
import ErrorLine from './ErrorLine';
import styles from './index.module.scss';
// import DownloadAppButton from '../DownloadAppButton';

const cookies = new Cookies(),
    { sessionStorage } = window;

class Header extends Component {
  state = {
    categories: [],
    locationPrev: '/',
    mobileMenuOpen: false,
  };

  componentDidMount() {
    const { history, isOpen } = this.props;
    const { mobileMenuOpen } = this.state;
    const user = JSON.parse(sessionStorage.getItem('user-info-for-verified'));
    const isFaPage =
      window.location.pathname.includes('/fa') &&
      !window.location.pathname.includes('face') &&
      !window.location.pathname.includes('faq');
    const showFAHeader = ( isFaPage
      || window.location.pathname.includes('raw-material-publish')
      || window.location.pathname.includes('spl-generate')
    ) && user?.access;

    if (!showFAHeader) {
      const gfpAppWrap = document.getElementsByClassName('gfp-app-wrap');
      gfpAppWrap[0].classList.add(isOpen ? '-with-banner' : '-fixed');
      setScrolledHeader();
    }

    this.unlistenHistory = history.listen(() => {
      if (mobileMenuOpen) {
        this.handleMobileMenuToggle();
      }
    });
  }

  componentDidUpdate(
    prevProps, prevState, snapshot,
  ) {
    const { isOpen } = this.props;
    const user = JSON.parse(sessionStorage.getItem('user-info-for-verified'));
    const isFaPage =
        window.location.pathname.includes('/fa') &&
        !window.location.pathname.includes('faq') &&
        !window.location.pathname.includes('face');
    const showFAHeader = ( isFaPage
        || window.location.pathname.includes('raw-material-publish')
        || window.location.pathname.includes('spl-generate')
      ) && user?.access;

    if (!showFAHeader) {
      const gfpAppWrap = document.getElementsByClassName('gfp-app-wrap');
      gfpAppWrap[0].classList.add(isOpen ? '-with-banner' : '-fixed');
      setScrolledHeader();
    }

  }

  componentWillUnmount() {
    if (this.unlistenHistory) {
      this.unlistenHistory();
    }
  }

  handleMobileMenuToggle = (state, moreButtonClicked, lastLocation, isMobileMenu) => {
    const { mobileMenuOpen } = this.state;
    this.setState({
      mobileMenuOpen: typeof state === 'boolean' ? state : !mobileMenuOpen,
    });

    if (moreButtonClicked) {
      // const { location, formulatorUser } = this.props;

      if (['/', '/accounts/login', '/accounts/login', '/contractmanufacturers', '/brands', '/retailers', '/ingredientsuppliers', '/consumerstudies', '/about', '/careers', '/compare', '/consumers/faq', '/face-it-mag', '/shop', '/faq', '/blog'].includes(lastLocation?.pathname)) {
        // segmentsFooterTrack({
        //   user: formulatorUser,
        //   name: ['/contractmanufacturers', '/brands', '/retailers', '/ingredientsuppliers', '/consumerstudies'].includes(lastLocation?.pathname) ? 'Good Face Project For Button Clicked' : 'More Button Clicked',
        //   description: ['/contractmanufacturers', '/brands', '/retailers', '/ingredientsuppliers', '/consumerstudies'].includes(lastLocation?.pathname) ?
        //       'User clicks on one of the buttons under the Good Face Project For Button: \n' +
        //       'Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation\n' +
        //       '\n' +
        //       'in any of the following pages:  About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page\n'
        //       : 'User clicks on one of the buttons under the More button: \n' +
        //       'About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ\n' +
        //       '\n' +
        //       'in any of the following pages:  About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page\n',
        //   track_fields: {
        //     $current_url: window.location.href,
        //     current_page: URLS_DETECT[location.pathname],
        //     button_name: moreButtonClicked,
        //     button_location: isMobileMenu ? 'Minimized Menu' : 'Header Menu',
        //   },
        // });
      }
    }
  };

  render() {
    const {
      lastLocation,
      formulatorUser,
      companyPermissions,
      showMaintenanceMessage,
      updateBrowser,
      windowSize,
      featureFlags,
    } = this.props;
    const user = JSON.parse(sessionStorage.getItem('user-info-for-verified'));
    const isFaPage =
      window.location.pathname.includes('/fa') &&
      !window.location.pathname.includes('faq') &&
        !window.location.pathname.includes('face');
    const isSupplier = !!companyPermissions?.find((item) => item.name === 'supplier_basic_account');
    const showGlobalMessage = window.location.pathname.includes(isSupplier ? '/fa/raw-material': '/fa/formulas') && !cookies.get("gfp-global-naming-message");
    const showFAHeader =
      (isFaPage || window.location.pathname.includes('raw-material-publish') || window.location.pathname.includes('spl-generate')) &&
      user &&
      user.access;

    const headerElement = document.getElementById('header');

    if (showFAHeader) {
      headerElement.classList.add('formulator-header');
    } else {
      headerElement.classList.remove('formulator-header');
    }

    if (showMaintenanceMessage && showGlobalMessage) {
      headerElement.classList.remove('-with-global-message');
      headerElement.classList.add('-with-double-global-messages');
    } else if (showMaintenanceMessage || showGlobalMessage) {
      headerElement.classList.remove('-with-double-global-messages');
      headerElement.classList.add('-with-global-message');
    } else {
      headerElement.classList.remove('-with-global-message');
      headerElement.classList.remove('-with-double-global-messages');
    }

    const {
      location: { pathname },
      isOpen,
      location,
    } = this.props;

    const { mobileMenuOpen } = this.state;

    const withSearchBar = pathname.indexOf('/shop') !== -1 || pathname.indexOf('/product/') !== -1 || pathname.indexOf('/search') !== -1;

    return showFAHeader ? (
      <HeaderFA
        updateBrowser={updateBrowser}
        windowSize={windowSize}
      />
    ) : (
      <React.Fragment>
        <Drawer
          open={mobileMenuOpen}
          onClose={this.handleMobileMenuToggle}
          width="100%"
        >
          <div className={styles['drawer']}>
            <SearchBox
              className={styles['drawer__search-box']}
              inputClassName={styles['drawer__search-box-input']}
              inputWrapClassName={styles['drawer__search-box-input-wrap']}
              btnClassName={styles['drawer__search-box-icon']}
            />

            <ul className={styles['drawer__menu']}>
              <li className="has-children">
                <SlideDown
                    header={<a href="#">Good Face Project for</a>}
                    icon={SlideDown.icons.arrow}
                >
                  <ul>
                    <li>
                      <Link
                          to="/contractmanufacturers"
                          onClick={() =>
                              this.handleMobileMenuToggle(
                                  false,
                                  'Formulators & Manufacturers',
                                  lastLocation,
                                  true,
                              )
                          }
                      >
                        Formulators & Manufacturers
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="/brands"
                          onClick={() =>
                              this.handleMobileMenuToggle(
                                  false,
                                  'Brands',
                                  lastLocation,
                                  true,
                              )
                          }
                      >
                        Brands
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="/retailers"
                          onClick={() =>
                              this.handleMobileMenuToggle(
                                  false,
                                  'Retailers',
                                  lastLocation,
                                  true,
                              )
                          }
                      >
                        Retailers
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="/ingredientsuppliers"
                          onClick={() =>
                              this.handleMobileMenuToggle(
                                  false,
                                  'Ingredient Suppliers',
                                  lastLocation,
                                  true,
                              )
                          }
                      >
                        Ingredient Suppliers
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="/consumerstudies"
                          onClick={() =>
                              this.handleMobileMenuToggle(
                                  false,
                                  'Claim Validation',
                                  lastLocation,
                                  true,
                              )
                          }
                      >
                        Claim Validation
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="/regulatoryservices"
                          onClick={() =>
                              this.handleMobileMenuToggle(
                                  false,
                                  'Regulatory Services',
                                  lastLocation,
                                  true,
                              )
                          }
                      >
                        Regulatory Services
                      </Link>
                    </li>
                  </ul>
                </SlideDown>
              </li>

              <li className="has-children">
                <SlideDown
                    header={<a href="#">More</a>}
                    icon={SlideDown.icons.arrow}
                >
                  <ul>
                    <li>
                      <Link
                          to="/about"
                          onClick={() =>
                              this.handleMobileMenuToggle(
                                  false,
                                  'About',
                                  lastLocation,
                                  true,
                              )
                          }
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="/blog"
                          onClick={() =>
                              this.handleMobileMenuToggle(
                                  false,
                                  'Blog',
                                  lastLocation,
                                  true,
                              )
                          }
                      >
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="/faq"
                          onClick={() =>
                              this.handleMobileMenuToggle(false, 'FAQ', true)
                          }
                      >
                        FAQ
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link*/}
                    {/*    to="#"*/}
                    {/*    onClick={() =>*/}
                    {/*      this.handleMobileMenuToggle(*/}
                    {/*        false,*/}
                    {/*        'Good Face Explains',*/}
                    {/*        true,*/}
                    {/*      )*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    Good Face Explains*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    <li>
                      <Link
                          to="/careers"
                          onClick={() =>
                              this.handleMobileMenuToggle(false, 'Careers', true)
                          }
                      >
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="/compare"
                          onClick={() =>
                              this.handleMobileMenuToggle(false, 'Compare', true)
                          }
                      >
                        Compare
                      </Link>
                    </li>
                    {featureFlags?.trust_page?.enabled &&
                      <li>
                        <Link
                          to="/trust"
                          onClick={() => this.handleMobileMenuToggle(false, 'Trust', true)}
                        >
                          Trust
                        </Link>
                      </li>
                    }
                  </ul>
                </SlideDown>
              </li>

              <li>
                <Link
                    to="/accounts/login?type=brand&next=/sa/products"
                    onClick={() => this.handleMobileMenuToggle(false)}
                >
                  Login
                </Link>
              </li>
            </ul>

            {/*<DownloadAppButton*
              className={styles['drawer__app-btn']}
              onClick={this.handleMobileMenuToggle}
            >
              Get The App
            </DownloadAppButton>*/}
          </div>
        </Drawer>

        <Sticky top={0} innerZ={200}>
          <NoticeLine />
          <ErrorLine />
        </Sticky>

        {(isOpen) ? (
          <PromoBar
            additionalClassName={
              !showFAHeader && isOpen
                ? '-fixed-header'
                : ''
            }
          />
        ) : null}

        <Section
          renderAs="header"
          id={'header-fixed'}
          className={classNames(
            styles['header'],
            !showFAHeader
              ? styles['-fixed']
              : '',
            !showFAHeader && isOpen
              ? styles['-with-banner']
              : '',
            config.analytic.classes.header,
            {
              [styles['header--home']]: pathname === '/',
            },
          )}
        >
          <Container className={styles['header__inner']}>
            <Logo className={styles['header__logo']} />

            {withSearchBar ? (
              <SearchBox
                className={`${styles['header__search-box']} is-hidden-touch`}
                inputClassName={styles['header__search-box-input']}
              />
            ) : null}

            <ul
                className={classNames(
                    styles['header__menu'],
                    withSearchBar ? styles['-with-search'] : '',
                )}
            >
              <li className="has-children">
                Good Face Project for
                <ul>
                  <li>
                    <Link
                      to="/contractmanufacturers"
                      onClick={() =>
                        this.handleMobileMenuToggle(
                          false,
                          'Formulators & Manufacturers',
                          lastLocation,
                          false,
                        )
                      }
                    >
                      Formulators & Manufacturers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/brands"
                      onClick={() =>
                        this.handleMobileMenuToggle(
                          false,
                          'Brands',
                          lastLocation,
                          false,
                        )
                      }
                    >
                      Brands
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/retailers"
                      onClick={() =>
                        this.handleMobileMenuToggle(
                          false,
                          'Retailers',
                          lastLocation,
                          false,
                        )
                      }
                    >
                      Retailers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ingredientsuppliers"
                      onClick={() =>
                        this.handleMobileMenuToggle(
                          false,
                          'Ingredient Suppliers',
                          lastLocation,
                          false,
                        )
                      }
                    >
                      Ingredient Suppliers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/consumerstudies"
                      onClick={() =>
                        this.handleMobileMenuToggle(
                          false,
                          'Claim Validation',
                          lastLocation,
                          false,
                        )
                      }
                    >
                      Claim Validation
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/regulatoryservices"
                      onClick={() =>
                        this.handleMobileMenuToggle(
                          false,
                          'Regulatory Services',
                          lastLocation,
                          false,
                        )
                      }
                    >
                      Regulatory Services
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-children">
                More
                <ul>
                  <li>
                    <Link
                      to="/about"
                      onClick={() =>
                        this.handleMobileMenuToggle(
                          false,
                          'About',
                          lastLocation,
                          false,
                        )
                      }
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="/careers"
                        onClick={() =>
                            this.handleMobileMenuToggle(
                                false,
                                'Careers',
                                lastLocation,
                                false,
                            )
                        }
                    >
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="/compare"
                        onClick={() =>
                            this.handleMobileMenuToggle(
                                false,
                                'Compare',
                                lastLocation,
                                false,
                            )
                        }
                    >
                      Compare
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="/blog"
                        onClick={() =>
                            this.handleMobileMenuToggle(
                                false,
                                'Consumer Blog',
                                lastLocation,
                                false,
                            )
                        }
                    >
                      Blog
                    </Link>
                  </li>

                  <li>
                    <Link
                        to="/faq"
                        onClick={() =>
                            this.handleMobileMenuToggle(
                                false,
                                'FAQ',
                                lastLocation,
                                false,
                            )
                        }
                    >
                      FAQ
                    </Link>
                  </li>
                  {featureFlags?.trust_page?.enabled &&
                    <li>
                      <Link
                        to="/trust"
                        onClick={() => this.handleMobileMenuToggle(false, 'Trust', lastLocation, false)}
                      >
                        Trust
                      </Link>
                    </li>
                  }
                  {/*<li>*/}
                  {/*  <Link*/}
                  {/*    to="#"*/}
                  {/*    onClick={() => this.handleMobileMenuToggle(false)}*/}
                  {/*  >*/}
                  {/*    Good Face Explains*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              </li>
            </ul>

            <Button
                onClick={() => {
                  window.open('/demo', '_blank');

                  if (URLS_DETECT[location.pathname]) {
                    segmentsFooterTrack({
                      user: formulatorUser,
                      name: 'Book a Demo Button Clicked',
                      description: 'User clicks on Book a Demo button in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page',
                      track_fields: {
                        $current_url: window.location.href,
                        current_page: URLS_DETECT[location.pathname],
                      },
                    });
                  }
                }}
                className={classNames(
                    styles['header__request-demo-btn'],
                    styles['-book'],
                )}
            >
              {window.innerWidth < 768 ? 'Demo' : 'Book a Demo'}
            </Button>

            {/*{*/}
            {/*  ['/', '/formulator', '/experts', '/amplify'].includes(*/}
            {/*    pathname,*/}
            {/*  ) && (*/}
            {/*    <ScrollLink*/}
            {/*      to="contact-form"*/}
            {/*      smooth={true}*/}
            {/*      className={`${styles['header__request-demo-btn']} is-hidden-mobile`}*/}
            {/*    >*/}
            {/*      <Button>Request Demo</Button>*/}
            {/*    </ScrollLink>*/}
            {/*  )}*/}

            {user && user.access ? (
              <Button
                href="/fa/formulas"
                btnStyle="secondary"
                className={`${styles['header__formulator-btn']} is-hidden-touch is-hidden-desktop-only`}
              >
                Go to Formulator
              </Button>
            ) : (
              <Button
                href="/accounts/login?type=brand&next=/sa/products"
                btnStyle="secondary"
                className={`${styles['header__login-btn']} is-hidden-touch is-hidden-desktop-only`}
              >
                <LoginIcon />
                Login
              </Button>
            )}

            <MenuToggle
              className={`${styles['header__menu-toggle']} is-hidden-widescreen`}
              onClick={this.handleMobileMenuToggle}
            />
          </Container>
        </Section>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  isOpen: state.general.promoBar,
  featureFlags: state.user.featureFlags,
  showMaintenanceMessage: state.formulator.showMaintenanceMessage,
  companyPermissions: state.formulator.companyPermissions,
  formulatorUser: state.formulator.user,
}))(withRouter(Header));
