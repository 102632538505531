import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function useLastLocation() {
  const location = useLocation();
  const lastLocation = useRef(location);

  useEffect(() => {
    lastLocation.current = location;
  }, [location]);

  return lastLocation.current;
}

export default useLastLocation;
