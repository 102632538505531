import {
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_SUB_CATEGORIES,
  GET_PRODUCT_TYPES,
  GET_PRODUCT_BRANDS,
  GET_PRODUCTS,
  GET_PRODUCT_RESTRICTION_TAGS,
  GET_USER_PROFILE,
  GET_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_USERS,
  UPDATE_USER_COMPANY,
  GET_USER_COMPANY_CURRENCY,
  GET_REGULATORY,
  GET_ACTIVES,
  GET_FOLDERS,
  CREATE_PRODUCTS,
  GLOBAL_ERROR,
  CLOSE_ERROR,
  GET_TOXIN_CATEGORIES,
  GET_TOXINS,
  GET_FORMULATOR_PRODUCT,
  GET_PRODUCT_BENEFITS,
  GET_INGREDIENTS,
  GET_BLACK_LIST,
  GET_PARTNERSHIPS,
  GET_PARTNERSHIPS_SHARED_ITEMS,
  GET_COMPOUND_INGREDIENTS,
  GET_INGREDIENTS_FOR_LIBRARY,
  GET_INGREDIENT,
  COPY_INGREDIENT,
  GET_COMPANIES,
  GET_BLACK_LIST_ITEMS,
  UPDATE_PRODUCTS,
  FORBIDDEN_BLACKLIST,
  GET_COMPOUND_INGREDIENT,
  FIELD_ERRORS,
  GET_PHASES,
  GET_REPORTS,
  GET_FUNCTIONS_LIST,
  UPDATE_INGREDIENT,
  GET_SHARED_PRODUCT,
  GET_CERTIFICATIONS,
  GET_SUPPLIERS,
  UPDATE_COMPOUND_INGREDIENT,
  GET_COUNTRIES,
  GET_SOURCES,
  CREATE_COMPOUND_INGREDIENT,
  GET_RESTRICTION_ANALYZE,
  CLEAR_RESTRICTIONS,
  CLEARE_PRODUCT,
  UPDATE_BLACK_LIST_ITEM,
  GET_FILE_TYPES,
  GET_FILE_TAGS,
  DELETE_RM_FILE_SUCCESS,
  GET_PRODUCT_NOTES,
  REQUEST_REVIEW_INGREDIENT,
  REQUEST_REVIEW_RM,
  GET_MANUFACTURERS,
  GET_TAGS,
  GET_CUSTOM_TAGS,
  GET_USER_NOTIFICATIONS,
  GET_APPLICATIONS,
  GET_COMPOUND_INGREDIENT_PARAM,
  UPDATE_COMPOUND_INGREDIENT_PARAM_LIST,
  GET_COMPOUND_INGREDIENT_IMPUTITY,
  UPDATE_COMPOUND_INGREDIENT_IMPURITY_LIST,
  GET_COMPOUND_INGREDIENT_ALLERGEN,
  UPDATE_COMPOUND_INGREDIENT_ALLERGEN_LIST,
  CLEAR_PARAMS,
  UPDATE_COMPOUND_INGREDIENT_IMPURIT_FIELD,
  UPDATE_COMPOUND_INGREDIENT_PARAM_FIELD,
  UPDATE_COMPOUND_INGREDIENT_ALLERGEN_FIELD,
  GET_PRODUCT_DBS,
  CREATE_PRODUCT_DBS,
  UPDATE_PRODUCT_DBS,
  SET_UPLOADED_DOCS,
  GET_PRODUCT_SIZES,
  UPDATE_PRODUCT_SIZES,
  CREATE_PRODUCT_SIZES,
  DELETE_PRODUCT_SIZES,
  CLEAR_FORMULATOR_PRODUCT,
  CLEAR_PRODUCT_SIZES,
  SET_FORMULA_EDITMODE,
  SET_SHOW_EDIT_CONFIRM_MODAL,
  SET_RM_EDITMODE,
  GET_SHARED_PRODUCT_BRANDS,
  GET_ORIGINS,
  CLEAR_PRODUCT_DBS,
  GET_TABLE_LAYOUT,
  UPDATE_TABLE_LAYOUT,
  UPDATE_PRODUCT_RESTRICTION_TAGS,
  GET_REGIONS,
  SET_PREVIOUS_LOCATION,
  REQUEST_SYNC_CENTRIC_SUCCESS,
  GET_FORMULATOR_FORMULA_PARAMS,
  GET_FORMULATOR_FORMULA_COMPOUND_PARAMS,
  GET_FORMULA_COMPOUND_PARAM_INGREDIENTS,
  UPDATE_FORMULA_COMPOUND_CHILD_INGREDIENT,
  UPDATE_FORMULA_INGREDIENT,
  UPDATE_FORMULA_INGREDIENT_COMPOUND,
  GET_FORMULA_FILES,
  DELETE_FORMULA_FILE,
  UPDATE_FORMULA_FILE,
  UPLOAD_FORMULA_FILE,
  UPDATE_REQUEST_REVIEW_INGREDIENT,
  ADD_FORMULA_INGREDIENT_COMPOUND,
  DELETE_FORMULA_INGREDIENT_COMPOUND,
  UPDATE_PRODUCT_GROUPS,
  GET_FORMULATOR_FORMULA_TOTAL_CONCENTRATION,
  GET_FORMULATOR_FORMULA_TOTAL_COST,
  UPDATE_FORMULATOR_FORMULA_NF_PARAMS,
  CLEAR_FORMULATOR_FORMULA,
  GET_COMPOUND_INGREDIENT_INCIDENTAL,
  UPDATE_COMPOUND_INGREDIENT_STATUS,
  SET_SHOW_MAINTENANCE_MESSAGE,
  UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_LIST,
  UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_FIELD,
  GET_USER_PERMISSIONS,
  GET_COMPANY_PERMISSIONS,
  UPDATE_PRODUCTS_STATUS,
  GET_ALL_PERMISSIONS,
  GET_EDITED_USER_PERMISSIONS,
  GET_IN_MARKET_BRANDS,
  GET_EDITED_USER,
  GET_ALL_PERMISSIONS_STARTED,
  GET_COMPANY_GRAND_CATEGORIES,
  GET_COMPANY_TYPES,
  GET_COMPANY_BRANDS,
  GET_SCREENING_NOTIFICATIONS,
  RESORT_FORMULA_PARAMS,
  LOAD_COMPANY_PROFILE_USERS,
  GET_MAINTENANCE_MESSAGES,
  GET_MAX_PRICE,
  GET_FORMULATOR_FORMULA_TOTAL_NATURALITY,
  GET_FORMULATOR_FORMULA_TOTAL_BIODEGRADABILITY,
  UPDATE_CHANGED_RM_IN_FORMULA,
  GET_SMART_FORMULAS,
  GET_FORMULATOR_FORMULA_SMART_FORMULAS,
  GET_FORMULA_SMART_FORMULA_INGREDIENTS,
  UPDATE_FORMULA_SMART_INGREDIENT,
  GET_SMART_PHASES,
  UNLOCK_FORMULATOR_PRODUCT,
  GET_FORMULATOR_FORMULA_TOTAL_NATURAL_ORIGIN,
  GET_FORMULATOR_FORMULA_TOTAL_ORGANIC_CONTENT,
  GET_FORMULATOR_FORMULA_TOTAL_ORGANIC_ORIGIN,
  GET_FORMULATOR_FORMULA_LOCAL_CONCENTRATION,
  GET_PRODUCT_VARIATIONS,
  DELETE_FORMULAS_VARIATION,
  CREATE_FORMULAS_VARIATION, UPDATE_FORMULAS_VARIATION,
  GET_FORMULATOR_FORMULA_VARIATION_COMPOUND_PARAMS,
  ADD_FORMULA_VARIATION_COMPOUND,
  GET_FORMULATOR_FORMULA_VARIATIONS_SMART_FORMULAS, CLEAR_VARIATION_PARAMS,
  REPLACE_MAIN_VARIATION_PARAMS, UPDATE_FORMULATOR_VARIATION_SMART_FORMULA_PARAMS,
} from '../actions/types';
import { modifyReviewRequested } from "../utils/modifyReviewRequested";

import {RESTRICTION_TAG_TYPES} from "./const";
import { shareFormulaProductsBulkWithPartners } from '../actions';
import { FIXED_POSITION_PARAM, POSITION_PARAM } from '../pages/FormulatorFormulasView/consts';


export default function reducer(state = {isLoggedIn: false, role: false, formulaEditmode: false, showEditConfirmModal: false, editMode: false, formulaSmartCompoundParams: []}, action) {

  switch (action.type) {
    case SET_RM_EDITMODE:
        return {
            ...state,
            editMode: action.value,
        }
    case SET_SHOW_EDIT_CONFIRM_MODAL:
        return {
            ...state,
            showEditConfirmModal: action.value,
        }
    case SET_FORMULA_EDITMODE:
        return {
            ...state,
            formulaEditmode: action.value,
        }
    case GET_PRODUCT_CATEGORIES:
      return {
          ...state,
        categories: action.data,
      };
    case GET_PRODUCT_SUB_CATEGORIES:
        return {
            ...state,
          subCategories: action.data,
        };
    case GET_PRODUCT_TYPES:
        return {
            ...state,
          types: action.data,
        };
    case GET_APPLICATIONS:
      return {
        ...state,
        applications: action.data,
      };
    case GET_PRODUCT_BRANDS:
        return {
            ...state,
          brands: action.data,
        }
    case GET_IN_MARKET_BRANDS:
      return {
        ...state,
        in_market_brands: action.isConcat
          ? [...state.in_market_brands, ...action.data]
          : action.data,
      }
    case GET_SHARED_PRODUCT_BRANDS:
        return {
          ...state,
          shared_brands: action.data,
        }
    case GET_PRODUCT_SIZES:
      return {
        ...state,
        product_sizes: action.data,
      }
    case CLEAR_PRODUCT_SIZES:
      return {
        ...state,
        product_sizes: undefined,
      }
    case CREATE_PRODUCT_SIZES:
      const currentSizes = state.product_sizes || [];

      return {
        ...state,
        product_sizes: [...currentSizes, action.data],
      }
    case UPDATE_PRODUCT_SIZES:
      return {
        ...state,
        product_sizes: state.product_sizes?.map((size) => {
          if (size.id === action.data.id) {
            return action.data;
          }

          return size;
        }),
      }
    case DELETE_PRODUCT_SIZES:
      return {
        ...state,
        product_sizes: state.product_sizes?.filter((size) => {
          return size.id !== action.data.id;
        })
      }
    case GET_PRODUCTS:
      return {
        ...state,
        consumer_products: action.data,
      }
    case GET_PRODUCT_DBS:
    case CREATE_PRODUCT_DBS:
    case UPDATE_PRODUCT_DBS:
      return {
        ...state,
        consumer_product: action.data,
        error: ''
      }
    case CLEAR_PRODUCT_DBS:
      return {
        ...state,
        consumer_product: undefined,
      }
    case GET_USER_PROFILE:
        return {
          ...state,
          isLoggedIn: true,
          user: {
            ...state.user,
            ...action.data,
            ...(state.user?.company ? { company: state.user.company } : {}),
          },
        };
    case GET_USER_PERMISSIONS:
        return {
          ...state,
          user: {
            ...state.user,
            permissions: action.data
          },
        };
    case GET_EDITED_USER:
      return {
        ...state,
        editedUser: {
          ...action.data,
          ...(
            state.editedUser?.permissions
              ? { permissions: state.editedUser?.permissions }
              : {}
          ),
        },
      };
    case GET_EDITED_USER_PERMISSIONS:
        return {
          ...state,
          editedUser: {
            ...state.editedUser,
            permissions: action.data
          },
        };
    case SET_SHOW_MAINTENANCE_MESSAGE:
      return {
        ...state,
        showMaintenanceMessage: action.data,
      };
    case GET_MAINTENANCE_MESSAGES:
      return {
        ...state,
        maintenanceMessages: action.data,
      };
    case GET_COMPANY_PERMISSIONS:
        return {
          ...state,
          companyPermissions: action.data,
        };
    case GET_ALL_PERMISSIONS:
        return {
          ...state,
          user: {
            ...state.user,
            permissions: action.data?.user_permissions,
          },
          companyPermissions: action.data?.company_permissions,
          allPermissionsLoading: false,
        };
    case GET_ALL_PERMISSIONS_STARTED:
        return {
          ...state,
          allPermissionsLoading: true,
        };
    case GET_COMPANY_GRAND_CATEGORIES:
        const grandCategoryPermission = action.data?.company_permissions?.find((item) => item.name === 'grand_categories');

        return {
          ...state,
          companyGrandCategories: grandCategoryPermission?.condition?.categories || [],
        }
    case GET_COMPANY_TYPES:
      return {
        ...state,
        companyTypes: action.data,
      };
    case GET_COMPANY_BRANDS:
      return {
        ...state,
        companyBrands: action.data.data,
      };
    case GET_COMPANY_PROFILE:
        return {
          ...state,
          company: action.data,
          user: {
            ...state.user,
            company: action.data,
          }
        };
    case GET_COMPANY_PROFILE_USERS:
        return {
            ...state,
          company_users: action.data?.data || [],
          company_users_count: action.data?.count || 0,
        };
    case LOAD_COMPANY_PROFILE_USERS:
        return {
          ...state,
          company_users: [
            ...state.company_users,
            ...action.data?.data || [],
          ],
          company_users_count: action.data?.count || 0,
        }
    case UPDATE_USER_COMPANY:
        return {
            ...state,
          company_users_company: action.data,
        };
    case GET_USER_COMPANY_CURRENCY:
        return{
            ...state,
            currency: action.data
        };
    case GET_REGULATORY:
        return {
            ...state,
          regulatory_info: action.data,
        };
    case GET_ACTIVES:
        return {
            ...state,
          actives: action.data,
        };
    case GET_FOLDERS:
        return {
            ...state,
          folders: action.data,
        };
    case CREATE_PRODUCTS:
        return {
            ...state,
          product: action.data,
            error: ""
        };
    case UNLOCK_FORMULATOR_PRODUCT:
      return {
        ...state,
        product: {
          ...state.product,
          is_locked: action.data.is_locked,
        },
        error: ""
      };
    case GET_TOXIN_CATEGORIES:
        return {
            ...state,
          toxinCategories: action.data,
        };
    case GET_TOXINS:
        return {
            ...state,
          toxins: action.data,
        };
    case UPDATE_PRODUCTS:
    case GET_FORMULATOR_PRODUCT:
    case GET_SHARED_PRODUCT:
        return {
            ...state,
          product: action.data,
          error: ''
        };
    case UPDATE_PRODUCTS_STATUS:
        return {
            ...state,
          product: {
            ...state.product,
            status: action.data.status,
          },
        };
    case UPDATE_PRODUCT_GROUPS:
        return {
          ...state,
          product: {
            ...state.product,
            groups: action.data,
          },
          error: ''
        }
    case CLEAR_FORMULATOR_PRODUCT:
      return {
        ...state,
        product: undefined,
        error: ''
      };
    case CLEAR_FORMULATOR_FORMULA:
      return {
        ...state,
        product: undefined,
        formulaParams: [],
        formulaCompoundParams: [],
        formulaSmartFormulas: [],
        mainFormulaSmartFormulas: [],
        mainFormulaCompoundParams: [],
        formulaTotalConcentration: 0,
        formulaTotalCost: 0,
        formulaFiles: [],
        restrictions: '',
        variations: [],
        error: ''
      }
    case REQUEST_SYNC_CENTRIC_SUCCESS:
        return {
            ...state,
          product: {
            ...state.product,
            ...(action.data?.centric_last_sync_sku_codes
              && { centric_last_sync_sku_codes: action.data.centric_last_sync_sku_codes }
            ),
          },
        }
    case GET_FORMULATOR_FORMULA_PARAMS:
      return {
        ...state,
        formulaParams: action.data,
      }
    case GET_FORMULATOR_FORMULA_COMPOUND_PARAMS:
      return {
        ...state,
        formulaCompoundParams: action.data,
      }
    case GET_FORMULATOR_FORMULA_VARIATION_COMPOUND_PARAMS:
      return {
        ...state,
        variationCompoundParams: action.data,
        mainFormulaCompoundParams: state.formulaCompoundParams,
        formulaCompoundParams: action.data, // test
      }
    case UPDATE_CHANGED_RM_IN_FORMULA:
      return {
        ...state,
        toggleChangedRM: action.data,
      }
    case GET_FORMULATOR_FORMULA_SMART_FORMULAS:
      return {
        ...state,
        formulaSmartFormulas: action.data,
      }
    case GET_FORMULATOR_FORMULA_VARIATIONS_SMART_FORMULAS:
      return {
        ...state,
        variationSmartFormulas: action.data,
        mainFormulaSmartFormulas: state.formulaSmartFormulas,
        formulaSmartFormulas: action.data,//test
      }
    case RESORT_FORMULA_PARAMS:
      return {
        ...state,
        formulaParams: state.formulaParams?.map((param) => {
          const currentIngredient = action.data.ingredients.filter(dataParam => dataParam.id === param.id);

          return {
            ...param,
            view_position: currentIngredient[0]?.view_position,
            // temp_position: currentIngredient[0]?.view_position,
          }
        }),
        formulaCompoundParams: state.formulaCompoundParams?.map((compound) => {
          const currentCompound = action.data.compounds.filter(dataCompound => dataCompound.id === compound.id);

          return {
            ...compound,
            view_position: currentCompound[0]?.view_position,
            // temp_position: currentCompound[0]?.view_position
          }
        }),
        formulaSmartFormulas: state.formulaSmartFormulas?.map((sf) => {
          const currentSF = action.data.smart.filter(dataSF => dataSF.id === sf.id);

          return {
            ...sf,
            view_position: currentSF[0]?.view_position,
            // temp_position: currentCompound[0]?.view_position
          }
        }),
      }
    case UPDATE_FORMULATOR_FORMULA_NF_PARAMS:
      return {
        ...state,
        formulaParams: state.formulaParams?.map((param) => {
          return {
            ...param,
            nf_ingredient: param.nf_ingredient
              ? { ...param.nf_ingredient, is_requested_review: true }
              : null
          }
        }),
        formulaCompoundParams: state.formulaCompoundParams?.map((compound) => {
          return {
            ...compound,
            compound: {
              ...compound.compound,
              params: compound.compound?.params?.map((child) => {
                return {
                  ...child,
                  nf_ingredient: child.nf_ingredient
                    ? { ...child.nf_ingredient, is_requested_review: true }
                    : null
                }
              }) || []
            }
          }
        })
      }
    case GET_FORMULATOR_FORMULA_TOTAL_CONCENTRATION:
      return {
        ...state,
        formulaTotalConcentration: action.data.total_concentration,
      }
    case GET_FORMULATOR_FORMULA_LOCAL_CONCENTRATION:
      return {
        ...state,
        formulaLocalConcentration: action.data.total_local_concentration,
      }
    case GET_FORMULATOR_FORMULA_TOTAL_COST:
      return {
        ...state,
        formulaTotalCost: action.data.total_cost,
      }
    case GET_FORMULATOR_FORMULA_TOTAL_BIODEGRADABILITY:
      return {
        ...state,
        formulaTotalBiodegradability: action.data.total_biodegradability,
      }
    case GET_FORMULATOR_FORMULA_TOTAL_NATURALITY:
      return {
        ...state,
        formulaTotalNaturality: action.data.total_naturality || null,
      }
    case GET_FORMULATOR_FORMULA_TOTAL_NATURAL_ORIGIN:
      return {
        ...state,
        formulaTotalNaturalOrigin: action.data.total_natural_origin || null,
      }
    case GET_FORMULATOR_FORMULA_TOTAL_ORGANIC_CONTENT:
      return {
        ...state,
        formulaTotalOrganicContent: action.data.total_organic_content || null,
      }
    case GET_FORMULATOR_FORMULA_TOTAL_ORGANIC_ORIGIN:
      return {
        ...state,
        formulaTotalOrganicOrigin: action.data.total_organic_origin || null,
      }
    case GET_FORMULA_COMPOUND_PARAM_INGREDIENTS:
      return {
        ...state,
        formulaCompoundParams: state.formulaCompoundParams?.map((compound) => {
          if (compound.id === action.data.id) {
            const result = {
              ...action.data,
              compound: {
                ...action.data.compound,
                isParamsLoaded: true,
              }
            }

            return result;
          }

          return compound;
        }),
        formulaSmartCompoundParams: action.isSmartChild ? [...state.formulaSmartCompoundParams.filter((compound) => compound.id !== action.data.id), action.data] : state.formulaSmartCompoundParams,
      }
    case GET_FORMULA_SMART_FORMULA_INGREDIENTS:

      const sortByFixedPosition = (
        (action.data.rm_list[0]?.view_position !== undefined && action.data.rm_list[0]?.view_position !== null)
        || (action.data.inci_list[0]?.view_position !== undefined && action.data.inci_list[0]?.view_position !== null));
      const positionFormulaParam = sortByFixedPosition ? FIXED_POSITION_PARAM : POSITION_PARAM;

      const concatedArray = action.data.rm_list.concat(action.data.inci_list);

      return {
        ...state,
        formulaSmartFormulas: state.formulaSmartFormulas?.map((sf) => {
          if (sf.id === action.data.id) {
            return {...action.data, params: positionFormulaParam === FIXED_POSITION_PARAM ? concatedArray.sort(
                (a, b) => a[positionFormulaParam] - b[positionFormulaParam]) : concatedArray,
              }
          }

          return sf;
        }),
      }
    case GET_FORMULA_FILES:
      return {
        ...state,
        formulaFiles: action.data || [],
      }
    case UPLOAD_FORMULA_FILE:
      return {
        ...state,
        formulaFiles: [...(state.formulaFiles || []), action.data],
      }
    case UPDATE_FORMULA_FILE:
      return {
        ...state,
        formulaFiles: state.formulaFiles.map((file) => {
          if (file.id === action.data.id) {
             return action.data;
          }

          return file;
        }),
      }
    case DELETE_FORMULA_FILE:
      return {
        ...state,
        formulaFiles: state.formulaFiles.filter((file) => {
          return file.id !== action.data;
        }),
      }
    case UPDATE_FORMULA_COMPOUND_CHILD_INGREDIENT:
      return {
        ...state,
        formulaCompoundParams: state.formulaCompoundParams?.map((compound) => {
          if (compound.id === action.data.ingredientId) {
            compound.compound.params = compound.compound.params.map((child) => {
              if (child.id === action.data.updatedChild.id) {
                return { ...child, ...action.data.updatedChild };
              }

              return child;
            })
          }

          return compound;
        })
      }
    case UPDATE_FORMULA_INGREDIENT:
      return {
        ...state,
        formulaParams: state.formulaParams?.map((param) => {
          if (param.id === action.data.id) {
            return {...action.data};
          }

          return param;
        }),
      }
    case UPDATE_FORMULA_INGREDIENT_COMPOUND:
      return {
        ...state,
        formulaCompoundParams: state.formulaCompoundParams?.map((compound) => {
          if (compound.id === action.data.id) {
            return {...action.data,};
          }

          return compound;
        }),
      }
    case UPDATE_FORMULA_SMART_INGREDIENT:
      return {
        ...state,
        formulaSmartFormulas: state.formulaSmartFormulas?.map((sf) => {
          if (sf.id === action.data.id) {
            return {...action.data, params: action.data.rm_list.concat(action.data.inci_list)};
          }

          return sf;
        }),
      }
    case ADD_FORMULA_INGREDIENT_COMPOUND:
      return {
        ...state,
        formulaCompoundParams: action.data,
      }
    case ADD_FORMULA_VARIATION_COMPOUND:
      return {
        ...state,
        variationCompoundParams: action.data,
        formulaCompoundParams: action.data, //test
      }
    case UPDATE_FORMULATOR_VARIATION_SMART_FORMULA_PARAMS:
      return {
        ...state,
        variationSmartFormulas: action.data,
        formulaSmartFormulas: action.data, //test
      }
    case DELETE_FORMULA_INGREDIENT_COMPOUND:
      return {
        ...state,
        formulaCompoundParams: state.formulaCompoundParams?.filter((compound) => {
          return !action.data.some((id) => compound.ingredientId === id);
        }),
      }
    case UPDATE_REQUEST_REVIEW_INGREDIENT:
      return {
        ...state,
        ...(action.data.compoundId ? {
            formulaCompoundParams: state.formulaCompoundParams?.map((compound) => {
              if (compound.compound.id === action.data.compoundId) {
                compound.compound.params = compound.compound.params.map((child) => {
                  if (child.nf_ingredient?.id === action.data.resp?.id) {
                    child.nf_ingredient = action.data.resp;
                  }

                  return child;
                })
              }

              return compound;
            })
          } : {
            formulaParams: state.formulaParams?.map((param) => {
              if (param.nf_ingredient?.id === action.data.resp?.id) {
                param.nf_ingredient = action.data.resp;
              }

              return param;
            })
          }
        )
      }
    case GET_PRODUCT_BENEFITS:
        return {
            ...state,
          benefits: action.data,
        };
    case GET_PRODUCT_VARIATIONS:
        return {
            ...state,
          variations: action.data,
        };
    case DELETE_FORMULAS_VARIATION:
      return {
        ...state,
        variations: state.variations.filter((variation) => variation.id !== action.variationId)
      }
    case CREATE_FORMULAS_VARIATION:
      return {
        ...state,
        variations: state.variations.concat(action.data),
      }
    case UPDATE_FORMULAS_VARIATION:
      return {
        ...state,
        variations: state.variations.map((variation) => {return action.variationId === variation.id ? { id: variation.id, ...action.data } : variation})
      }
    case GET_TABLE_LAYOUT:
    case UPDATE_TABLE_LAYOUT:
      return {
        ...state,
        tableLayout: action.data?.folder || undefined,
        rawMaterialLayout: action.data?.raw_material || undefined,
        formulaLayout: action.data?.formula || undefined,
      };
    case UPDATE_PRODUCT_RESTRICTION_TAGS:
        return {
          ...state,
          product: { ...state.product, restriction_tags: action.data.restriction_tags },
        };
    case GET_PRODUCT_RESTRICTION_TAGS:
        return {
            ...state,
            qualities: action.data
              .filter((item) =>
                item.restriction_type === RESTRICTION_TAG_TYPES.QUALITY ||
                !item.restriction_type
              ),
            productFormats: action.data
              .filter((item) => item.restriction_type === RESTRICTION_TAG_TYPES.FORMAT),
        };
    case GET_INGREDIENTS:
        return {
            ...state,
          ingredients: action.data,
        };
    case GET_BLACK_LIST:
        return {
            ...state,
            blackList: action.data.data,
        };
    case GET_BLACK_LIST_ITEMS:
        return {
            ...state,
            blackListItems: action.data
        };
    case GET_PARTNERSHIPS:
        return {
            ...state,
            error: '',
            partnerships: action.data,
        };
    case GET_PARTNERSHIPS_SHARED_ITEMS:
        return {
            ...state,
            partnershipsSharedItems: action.data,
        };
    case GET_COMPOUND_INGREDIENTS:
        return {
            ...state,
            compoundIngredients: action.data,
        };
    case GET_SMART_FORMULAS:
        return {
            ...state,
            smartFormulas: action.data,
        };
    case GET_INGREDIENTS_FOR_LIBRARY:
        return {
            ...state,
            libraryIngredients: action.data,
        };
    case GET_INGREDIENT:
        return {
            ...state,
            ingredient: action.data,
        };
  case COPY_INGREDIENT:
      return {
          ...state,
          copySuccess: true
      };
  case GET_COMPANIES:
      return {
          ...state,
          companies: action.data,
      };
    case GLOBAL_ERROR:
        return {
            ...state,
          copySuccess: false,
            request: action.request || '',
          error: action.data,
        };
    case FIELD_ERRORS:
        return {
            ...state,
          fieldErrors: action.data,
        };
      case FORBIDDEN_BLACKLIST:
          return {
              ...state,
              isForbiddenBlackList: true
          }
      case CREATE_COMPOUND_INGREDIENT:
      case UPDATE_COMPOUND_INGREDIENT:
      case GET_COMPOUND_INGREDIENT:
          return {
            ...state,
            compoundIngredient: action.data,
            // compoundIngredientParam: {
            //   data: action.data?.ingredient_params?.filter(
            //     (ing) => ing.param_type === 'inci',
            //   ),
            // },
            // compoundIngredientImpurity: {
            //   data: action.data?.ingredient_params?.filter(
            //     (ing) => ing.param_type === 'impurity',
            //   ),
            // },
            // compoundIngredientAllergen: {
            //   data: action.data?.ingredient_params?.filter(
            //     (ing) => ing.param_type === 'allergen',
            //   ),
            // },
            error: '',
          };
      case UPDATE_COMPOUND_INGREDIENT_STATUS:
          return {
            ...state,
            compoundIngredient: {
              ...state.compoundIngredient,
              status: action.data.status,
            },
          };
      case GET_COMPOUND_INGREDIENT_PARAM:
      case UPDATE_COMPOUND_INGREDIENT_PARAM_LIST:
          return {
              ...state,
              compoundIngredientParam: action.data,
              compoundIngredientINCI: action.data,
          }
      case GET_COMPOUND_INGREDIENT_IMPUTITY:
      case UPDATE_COMPOUND_INGREDIENT_IMPURITY_LIST:
          return {
              ...state,
              compoundIngredientImpurity: action.data,
          }
      case GET_COMPOUND_INGREDIENT_ALLERGEN:
      case UPDATE_COMPOUND_INGREDIENT_ALLERGEN_LIST:
          return {
              ...state,
              compoundIngredientAllergen: action.data,
          }
      case GET_COMPOUND_INGREDIENT_INCIDENTAL:
      case UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_LIST:
        return {
          ...state,
          compoundIngredientIncidental: action.data,
        }
      case GET_PHASES:
          return {
              ...state,
              phases: action.data.sort((a, b) => a.char > b.char ? 1 : -1),
          }
      case GET_SMART_PHASES:
          return {
              ...state,
              smartPhases: action.data.sort((a, b) => a.char > b.char ? 1 : -1),
          }
      case GET_REPORTS:
          return {
              ...state,
              reports: action.data,
          }
      case GET_FUNCTIONS_LIST:
          return {
              ...state,
              productFunctions: action.data,
          }
      case UPDATE_INGREDIENT:
          return {
              ...state,
              error: '',
              updatedProduct: action.data,
          }
      case GET_CERTIFICATIONS:
          return {
              ...state,
              certifications: action.data,
          }
      case GET_SUPPLIERS:
          return {
              ...state,
              suppliers: action.data,
          }
      case GET_MANUFACTURERS:
          return {
              ...state,
              manufacturers: action.data,
          }
      case GET_MAX_PRICE:
          return {
            ...state,
            maxPrice: action.data,
          }
      case GET_COUNTRIES:
          return {
              ...state,
              countries: action.data,
          }
      case GET_REGIONS:
          return {
              ...state,
              regions: action.data,
          }
      case GET_SOURCES:
          return {
              ...state,
              sources: action.data,
          }
      case GET_ORIGINS:
          return {
            ...state,
            origins: action.data,
          }
      case GET_RESTRICTION_ANALYZE:
          return {
              ...state,
              restrictions: action.data,
          }
      case CLOSE_ERROR:
        return {
            ...state,
          error: '',
        };
      case CLEAR_RESTRICTIONS:
        return {
            ...state,
            restrictions: '',
        };
      case CLEARE_PRODUCT:
          return {
              ...state,
              restrictions: '',
              phases: []
          };
      case UPDATE_BLACK_LIST_ITEM:
          return {
              ...state,
              blackListItem: action.data
          };
      case GET_FILE_TYPES:
          return {
              ...state,
              fileTypes: action.data
          };
      case GET_FILE_TAGS:
          return {
              ...state,
              fileTags: action.data
          };
      case GET_TAGS:
          return {
              ...state,
              tags: action.data
          };
      case GET_CUSTOM_TAGS:
          return {
              ...state,
              customTags: action.data
          };
      case DELETE_RM_FILE_SUCCESS:
          return {
              ...state,
              deleteType: DELETE_RM_FILE_SUCCESS
          };
      case GET_PRODUCT_NOTES:
          return {
              ...state,
              productNotes: action.data
          };
      case GET_USER_NOTIFICATIONS:
          return {
              ...state,
              notifications: action.data
          };
      case GET_SCREENING_NOTIFICATIONS:{
        return  {
          ...state,
          screeningNotifications: action.data.data
        }
      }
      case SET_PREVIOUS_LOCATION:
          return {
              ...state,
              previousLocation: action.data
          };
      case REQUEST_REVIEW_INGREDIENT:
          return {
              ...state,
              error: "",
              request: ""
          }
      case CLEAR_PARAMS:
          return {
              ...state,
              compoundIngredientINCI: '',
              compoundIngredientParam: '',
              compoundIngredientAllergen: '',
              compoundIngredientImpurity: '',
              compoundIngredientIncidental: '',
          }
      case UPDATE_COMPOUND_INGREDIENT_IMPURIT_FIELD:
          return {
              ...state,
              compoundIngredientImpurity: {data: action.data}
          }
      case UPDATE_COMPOUND_INGREDIENT_PARAM_FIELD:
          return {
              ...state,
              compoundIngredientINCI: {data: action.data},
              compoundIngredientParam: {data: action.data}
          }
      case UPDATE_COMPOUND_INGREDIENT_ALLERGEN_FIELD:
          return {
              ...state,
              compoundIngredientAllergen: {data: action.data}
          }
      case UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_FIELD:
          return {
            ...state,
            compoundIngredientIncidental: {data: action.data}
          }
      case SET_UPLOADED_DOCS:
          return {
              ...state,
              selectedFiles: action.data
          }
    case REQUEST_REVIEW_RM:
          return {
              ...state,
              compoundIngredientParam: {
                  data: state.compoundIngredientParam?.data?.map(
                    (item) => modifyReviewRequested(item, action.data)
                  ) || []
              },
              compoundIngredientImpurity: {
                  data: state.compoundIngredientImpurity?.data?.map(
                    (item) => modifyReviewRequested(item, action.data)
                  ) || []
              },
              compoundIngredientAllergen: {
                  data: state.compoundIngredientAllergen?.data?.map(
                    (item) => modifyReviewRequested(item, action.data)
                  ) || []
              },
            compoundIngredientIncidental: {
              data: state.compoundIngredientIncidental?.data?.map(
                  (item) => modifyReviewRequested(item, action.data)
              ) || []
            }
          }
    case CLEAR_VARIATION_PARAMS:
      return {
        ...state,
        variationCompoundParams: [],
        variationSmartFormulas: [],
        mainFormulaSmartFormulas: [],
        mainFormulaCompoundParams: [],
        formulaSmartFormulas: [state.formulaSmartFormulas[0]],
        formulaCompoundParams: action.data ? state.formulaCompoundParams : (state.mainFormulaCompoundParams || state.formulaCompoundParams)
      }
    case REPLACE_MAIN_VARIATION_PARAMS:
      return {
        ...state,
        variationCompoundParams: [],
        variationSmartFormulas: [],
        mainFormulaSmartFormulas: undefined,
        mainFormulaCompoundParams: undefined,
        formulaSmartFormulas: state.mainFormulaSmartFormulas ||  state.formulaSmartFormulas,
        formulaCompoundParams: state.mainFormulaCompoundParams || state.formulaCompoundParams,
      }
      default:
        return state;
  }
}
